import * as React from "react";
import { TabbedShowLayout, Tab, TabbedShowLayoutTabs } from "react-admin";

import { runeTheme } from "../../../common/RuneTheme";
import PatientList from "../../patient/PatientList";
import MemberList from "../../member/MemberList";

const OrgTabs = () => {
  return (
    <TabbedShowLayout
      sx={{
        background: runeTheme.palette.background,
        overflowX: "scroll",
        padding: "20px"
      }}
      tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
    >
      <Tab label="Patients" sx={{ textTransform: "capitalize" }}>
        <PatientList />
      </Tab>
      <Tab label="Members" sx={{ textTransform: "capitalize" }}>
        <MemberList />
      </Tab>
    </TabbedShowLayout>
  );
};

export default OrgTabs;
