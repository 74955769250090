// colors.ts

export const COLORS = {
  BLACK: "#000000",
  WHITE: "#FFFFFF",

  PRIMARY_200: "#EFF7FD",
  PRIMARY_300: "#DBEEFB",
  PRIMARY_400: "#C4E2F9",
  PRIMARY_500: "#A9D5F5",
  PRIMARY_600: "#8C6CF0",
  PRIMARY_700: "#74B4E2",
  PRIMARY_800: "#5B9FD0",
  PRIMARY_900: "#438DC2",
  PRIMARY_1000: "#006EBD",
  PRIMARY_1100: "#0063AA",
  PRIMARY_1200: "#005694",
  PRIMARY_1300: "#004D84",
  PRIMARY_1400: "#004271",
  PRIMARY_1500: "#00375F",
  PRIMARY_1600: "#002C4C",

  TEAL_100: "#D9F4ED",
  TEAL_200: "#B3E9DB",
  TEAL_300: "#8CDDC8",
  TEAL_400: "#66D2B6",
  TEAL_500: "#40C7A4",
  TEAL_600: "#3AB394",
  TEAL_700: "#339F83",
  TEAL_800: "#2D8B73",
  TEAL_900: "#267762",

  BONDI_100: "#CCECF1",
  BONDI_200: "#99D9E4",
  BONDI_300: "#66CCD6",
  BONDI_400: "#33B3C9",
  BONDI_500: "#00A0BB",
  BONDI_600: "#0090A8",
  BONDI_700: "#008096",
  BONDI_800: "#007083",
  BONDI_900: "#006070",

  // Special Meaning Colors - Tremor
  TREMOR_100: "#DFE9FE",
  TREMOR_200: "#BED4FC",
  TREMOR_300: "#9EBEFB",
  TREMOR_400: "#7DA9F9",
  TREMOR_500: "#5D93F8",
  TREMOR_600: "#5484DF",
  TREMOR_700: "#4A76C6",
  TREMOR_800: "#4167AE",
  TREMOR_900: "#385895",

  // Special Meaning Colors - Dyskinesia
  DYSKINESIA_100: "#D6ECD5",
  DYSKINESIA_200: "#ADD9AB",
  DYSKINESIA_300: "#85C680",
  DYSKINESIA_400: "#5CB356",
  DYSKINESIA_500: "#33A02C",
  DYSKINESIA_600: "#2E9028",
  DYSKINESIA_700: "#298023",
  DYSKINESIA_800: "#24701F",
  DYSKINESIA_900: "#1F601A",

  // Grey Colors
  GREY_100: "#F6F6F6",
  GREY_200: "#EFEFEF",
  GREY_300: "#DEDEDE",
  GREY_400: "#CBCBCB",
  GREY_500: "#ACACAC",
  GREY_600: "#939393",
  GREY_700: "#777777",
  GREY_800: "#5B5B5B",
  GREY_900: "#333333",

  // Cool Grey Colors
  COOL_100: "#EFF4FA",
  COOL_200: "#E4E9EF",
  COOL_300: "#D8DDE0",
  COOL_400: "#C2C7CC",
  COOL_500: "#A2A8AD",
  COOL_600: "#8B9093",
  COOL_700: "#717578",
  COOL_800: "#55595B",
  COOL_900: "#2C3033",

  // Warm Grey Colors
  WARM_100: "#FAF9F8",
  WARM_200: "#EEECEA",
  WARM_300: "#E0DDDA",
  WARM_400: "#CDC9C5",
  WARM_500: "#ADAAA6",
  WARM_600: "#95918C",
  WARM_700: "#797571",
  WARM_800: "#5E5B58",
  WARM_900: "#373431",

  // Other Colors
  ORANGE_100: "#FFDACD",
  ORANGE_200: "#FFB49B",
  ORANGE_300: "#FF8F69",
  ORANGE_400: "#FF6937",
  ORANGE_500: "#FF4405",
  ORANGE_600: "#E63D05",
  ORANGE_700: "#CC3604",
  ORANGE_800: "#B33004",
  ORANGE_900: "#992903",

  SKIN_100: "#F9F0DD",
  SKIN_200: "#E4D7BC",
  SKIN_300: "#ECD2C3",
  SKIN_400: "#D6A8D9",
  SKIN_500: "#C7B7D5",
  SKIN_600: "#C7D275",
  SKIN_700: "#B3D444",
  SKIN_800: "#664D48",
  SKIN_900: "#3B3C37",

  YELLOW_100: "#FFF5D2",
  YELLOW_200: "#FFEBAB",
  YELLOW_300: "#FFDF78",
  YELLOW_400: "#FFD74B",
  YELLOW_500: "#FFCD1E",
  YELLOW_600: "#E6B91B",
  YELLOW_700: "#CCA818",
  YELLOW_800: "#B39015",
  YELLOW_900: "#997B12",

  LIME_100: "#E0F4D2",
  LIME_200: "#C2E8A4",
  LIME_300: "#A3DD77",
  LIME_400: "#85D149",
  LIME_500: "#66C61C",
  LIME_600: "#5CB219",
  LIME_700: "#529E16",
  LIME_800: "#478B14",
  LIME_900: "#3D7711",

  GREEN_100: "#D0F1E8",
  GREEN_200: "#A0E2D0",
  GREEN_300: "#71D4B9",
  GREEN_400: "#41C5A1",
  GREEN_500: "#05BA8A",
  GREEN_600: "#10A87F",
  GREEN_700: "#019875",
  GREEN_800: "#00836A",
  GREEN_900: "#007160",

  CYAN_100: "#D8F3F7",
  CYAN_200: "#B2E7EF",
  CYAN_300: "#8BDDE6",
  CYAN_400: "#65CFDE",
  CYAN_500: "#3EC9D6",
  CYAN_600: "#38B0C1",
  CYAN_700: "#329CAB",
  CYAN_800: "#2B8996",
  CYAN_900: "#257580",

  BLUEBERRY_100: "#D6D7F0",
  BLUEBERRY_200: "#ADAFF0",
  BLUEBERRY_300: "#8588D1",
  BLUEBERRY_400: "#5C60C1",
  BLUEBERRY_500: "#3338B2",
  BLUEBERRY_600: "#2E32A0",
  BLUEBERRY_700: "#292D8E",
  BLUEBERRY_800: "#24277D",
  BLUEBERRY_900: "#1F226B",

  VIOLET_100: "#E7DEFF",
  VIOLET_200: "#CFBDF",
  VIOLET_300: "#B69DFF",
  VIOLET_400: "#9E7CFF",
  VIOLET_500: "#865BFF",
  VIOLET_600: "#7952E6",
  VIOLET_700: "#6B49CC",
  VIOLET_800: "#5E40B3",
  VIOLET_900: "#503799",

  PURPLE_100: "#F3EDFD",
  PURPLE_200: "#E7DBF7",
  PURPLE_300: "#DC9CFA",
  PURPLE_400: "#D07BFA",
  PURPLE_500: "#C45AF6",
  PURPLE_600: "#B051DD",
  PURPLE_700: "#9D48C5",
  PURPLE_800: "#893FAC",
  PURPLE_900: "#763694",

  PINK_100: "#FFE6F9",
  PINK_200: "#FFCCF4",
  PINK_300: "#FFB4EE",
  PINK_400: "#FF9BE9",
  PINK_500: "#FF82E3",
  PINK_600: "#E675CC",
  PINK_700: "#CC68B6",
  PINK_800: "#B35B9F",
  PINK_900: "#994E88",

  RED_100: "#F3CCDD",
  RED_200: "#E699A1",
  RED_300: "#D25852",
  RED_400: "#CD3342",
  RED_500: "#C10013",
  RED_600: "#AE0011",
  RED_700: "#9A000F",
  RED_800: "#87000D",
  RED_900: "#74000B",

  // Material UI Theming Colors
  PRIMARY_LIGHT: "#DBEEFB",
  PRIMARY_MAIN: "#006EBD",
  PRIMARY_DARK: "#004D84",

  SECONDARY_LIGHT: "#D6D7F0",
  SECONDARY_MAIN: "#3338B2",
  SECONDARY_DARK: "#292D8E",

  ERROR_LIGHT: "#E699A1",
  ERROR_MAIN: "#C10013",
  ERROR_DARK: "#9A000F",

  WARNING_LIGHT: "#FFEBAB",
  WARNING_MAIN: "#FFCD1E",
  WARNING_DARK: "#CCA418",

  SUCCESS_LIGHT: "#A0E2C3",
  SUCCESS_MAIN: "#12B76A",
  SUCCESS_DARK: "#0D804A"
};

export default COLORS;
