import { styled } from "@mui/material";
import { COLORS } from "./colors";

const ContentHeaderContainer = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  marginBottom: "1em"
});

const ContentDetail = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  gap: "4px",
  marginBottom: "0",
  paddingLeft: "32px"
});

const ContentAction = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const ContentDetailTopRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px",
  gap: "9px",
  height: "26px"
});

const ContentDetailTopLine = styled("div")({
  height: "26px",
  fontFamily: "'Work Sans'",
  fontStyle: "normal",
  lineHeight: "26px",
  letterSpacing: "-0.03em",
  color: COLORS.BLACK
});

const ContentDetailMiddleRow = styled("div")({
  "& .large-textfield": {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "42px",
    lineHeight: "49px",
    letterSpacing: "-0.03em",
    color: COLORS.BLACK
  }
});

const ContentDetailLastRow = styled("div")({});

const ContentDetailDescription = styled("div")({});

export {
  ContentHeaderContainer,
  ContentDetail,
  ContentDetailTopRow,
  ContentDetailTopLine,
  ContentDetailMiddleRow,
  ContentDetailLastRow,
  ContentAction,
  ContentDetailDescription
};
