import React from "react";

import { Children, isValidElement, useCallback } from "react";
import PropTypes from "prop-types";

import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import {
  useListContext,
  useResourceContext,
  Identifier,
  RaRecord,
  SortPayload,
  useTranslate
} from "ra-core";

import clsx from "clsx";

import { runeTheme } from "../RuneTheme";
import DatagridHeaderCell from "./DatagridHeaderCell";
import { DatagridClasses } from "./useDatagridStyles";

/**
 * Based on the default Datagrid Header component, custom component for Rune: Allow to have a second raw in the header
 *
 * Renders select all checkbox as well as column header buttons used for sorting.
 */
export const StudyDatagridHeader = (props: StudyDatagridHeaderProps) => {
  const {
    children,
    className,
    groupedLabels,
    hasBulkActions = false,
    hasExpand = false,
    isRowSelectable
  } = props;
  const resource = useResourceContext(props);
  const translate = useTranslate();
  const { sort, data, onSelect, selectedIds, setSort } = useListContext(props);

  const internalGroupedLabels = groupedLabels.map(
    // https://runelabs.atlassian.net/browse/SW-2470
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    (group: any, index: number) => {
      return {
        ...group,
        // For the first column, we add one to the colSpan to account for the checkbox column
        // Handles the case where there is no bulk actions, therefore no checkbox column
        // TODO: Make the computation easier to read
        colSpan:
          group.subLabels.length +
          (index ? 0 : (hasBulkActions ? 1 : 0) + (hasExpand ? 1 : 0)),
        // We don't had a border for the first column
        leftBorder: index ? `1px solid ${runeTheme.palette.divider}` : "0x"
      };
    }
  );

  const computeBorder = (source: string) => {
    // If the source is the first of one of the group, we add a border

    const firstLabelsOfGroups = internalGroupedLabels
      // We skip the first group
      .slice(1)
      .map((group: { subLabels: { source: string }[] }) => {
        return group.subLabels[0].source;
      });

    if (firstLabelsOfGroups.includes(source))
      return `1px solid ${runeTheme.palette.divider}`;
    return "none";
  };

  const updateSortCallback = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent> & {
        currentTarget: {
          dataset: {
            field: string;
            order: "ASC" | "DESC";
          };
        };
      }
    ) => {
      event.stopPropagation();
      const newField = event.currentTarget.dataset.field;
      const newOrder =
        sort.field === newField
          ? sort.order === "ASC"
            ? "DESC"
            : "ASC"
          : event.currentTarget.dataset.order;

      setSort({ field: newField, order: newOrder });
    },
    [sort.field, sort.order, setSort]
  );

  const updateSort = updateSortCallback;

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onSelect(
        event.target.checked
          ? selectedIds.concat(
              data
                .filter((record) => !selectedIds.includes(record.id))
                .filter((record) =>
                  isRowSelectable ? isRowSelectable(record) : true
                )
                .map((record) => record.id)
            )
          : []
      ),
    [data, onSelect, isRowSelectable, selectedIds]
  );

  const selectableIds = Array.isArray(data)
    ? isRowSelectable
      ? data
          .filter((record) =>
            isRowSelectable ? isRowSelectable(record) : true
          )
          .map((record) => record.id)
      : data.map((record) => record.id)
    : [];

  return (
    <TableHead className={clsx(className, DatagridClasses.thead)}>
      <TableRow
        className={clsx(DatagridClasses.row, DatagridClasses.headerRow)}
      >
        {internalGroupedLabels.map(
          (
            group: { colSpan: number; groupName: string; leftBorder: string },
            index: number
          ) =>
            // if groupName is null, don't show the top header row
            // if it's empty string, we still want to show it
            group.groupName != null ? (
              <TableCell
                key={index}
                align="left"
                colSpan={group.colSpan}
                sx={{
                  fontFamily: "Work Sans",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "14px",
                  letterSpacing: "0.05em",
                  textTransform: "uppercase",
                  color: "#000000",
                  backgroundColor: runeTheme.palette.background.light,
                  borderLeft: group.leftBorder,
                  borderBottom: "none"
                }}
              >
                {group.groupName}
              </TableCell>
            ) : null
        )}
      </TableRow>
      <TableRow
        className={clsx(DatagridClasses.row, DatagridClasses.headerRow)}
      >
        {hasExpand && (
          <TableCell className={DatagridClasses.headerCell}> </TableCell>
        )}
        {hasBulkActions && selectedIds && (
          <TableCell padding="checkbox" className={DatagridClasses.headerCell}>
            <Checkbox
              aria-label={translate("ra.action.select_all", {
                _: "Select all"
              })}
              className="select-all"
              color="primary"
              checked={
                selectedIds.length > 0 &&
                selectableIds.length > 0 &&
                selectableIds.every((id) => selectedIds.includes(id))
              }
              onChange={handleSelectAll}
            />
          </TableCell>
        )}

        {/* https://runelabs.atlassian.net/browse/SW-2470 */}
        {/* eslint-disable  @typescript-eslint/no-explicit-any */}
        {/* TODO - STUDY-280: Figure out if we can use internalGroupedLabels directly instead the hacky `computeBorder()` function */}
        {Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <DatagridHeaderCell
              className={clsx(
                DatagridClasses.headerCell,
                `column-${(field.props as any).source}`
              )}
              sort={sort}
              field={field}
              isSorting={
                sort.field ===
                ((field.props as any).sortBy || (field.props as any).source)
              }
              key={(field.props as any).source || index}
              resource={resource}
              updateSort={updateSort}
              align="left"
              sx={{
                /* Table column header */
                fontFamily: "Work Sans",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "16px",
                letterSpacing: "-0.03em",
                textTransform: "capitalize",
                color: runeTheme.palette.primary.main,
                borderLeft: computeBorder((field.props as any).source)
              }}
            />
          ) : (
            <>{field}</>
          )
        )}
        {/* eslint-enable  @typescript-eslint/no-explicit-any */}
      </TableRow>
    </TableHead>
  );
};

StudyDatagridHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string
  }),
  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: PropTypes.arrayOf(PropTypes.any),
  hasExpand: PropTypes.bool,
  hasBulkActions: PropTypes.bool,
  isRowSelectable: PropTypes.func,
  isRowExpandable: PropTypes.func,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  setSort: PropTypes.func
};

// https://runelabs.atlassian.net/browse/SW-2470
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export interface StudyDatagridHeaderProps<RecordType extends RaRecord = any> {
  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  groupedLabels: any;
  children?: React.ReactNode;
  className?: string;
  hasExpand?: boolean;
  hasBulkActions?: boolean;
  isRowSelectable?: (record: RecordType) => boolean;
  isRowExpandable?: (record: RecordType) => boolean;
  size?: "medium" | "small";
  // can be injected when using the component without context
  sort?: SortPayload;
  data?: RecordType[];
  onSelect?: (ids: Identifier[]) => void;
  onToggleItem?: (id: Identifier) => void;
  resource?: string;
  selectedIds?: Identifier[];
  setSort?: (sort: SortPayload) => void;
}

StudyDatagridHeader.displayName = "StudyDatagridHeader";
