import React, { useState } from "react";
import { Confirm, useListContext, useCreate, useNotify } from "react-admin";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";

import { runeTheme } from "../../../common/RuneTheme";

interface AddToCohortMenuItem {
  id: string;
  title: string;
  cohortPatientList: {
    aggregatedMetrics: {
      patiepatientsWithoutHeartRateAndSymptomDataCount: number;
      patientsWithoutHeartRateDataCount: number;
      patientsWithoutSymptomDataCount: number;
      totalPatientCount: number;
    };
  }[];
}

interface AddToCohortButtonProps {
  id: string;
  label: string;
  menuItems: AddToCohortMenuItem[];
}

export function AddToCohortButton(props: AddToCohortButtonProps) {
  const { id, label, menuItems } = props;
  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();
  const [create] = useCreate();

  // Set up state control of dropdown menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Set up state control of confirmation modal
  const [confirmOpen, setConfirmOpen] = useState(false);
  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [selectedCohort, setSelectedCohort] = useState<null | any>(null);

  const onClickItem = () => {
    setConfirmOpen(true);
  };
  const onClickConfirmCancel = () => {
    setSelectedCohort(null);
    setConfirmOpen(false);
    handleClose();
  };
  const onClickConfirm = () => {
    selectedIds.forEach((patient: string) => {
      create("CohortPatient", {
        data: {
          cohortId: selectedCohort?.id,
          patientId: patient
        }
      });
    });
    onClickConfirmCancel();
    notify(
      `Patients Added\n${selectedIds.length} patient(s) were added to ${selectedCohort?.title}.`,
      {
        type: "info",
        multiLine: true
      }
    );
    onUnselectItems();
  };

  return (
    <div>
      <Button
        id="menu-button"
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={onClick}
        startIcon={<AddIcon />}
        sx={{
          fontFamily: "Work Sans",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "22px",
          letterSpacing: "-0.03em",
          textDecorationLine: "underline",
          color: runeTheme.palette.primary.main,
          textTransform: "none",
          alignItems: "center",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "rgba(211, 47, 47, 0.12)"
          }
        }}
      >
        {label}
      </Button>
      <Menu
        id={id}
        MenuListProps={{
          "aria-labelledby": id + "-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems &&
          menuItems.map((item: AddToCohortMenuItem) => (
            <MenuItem
              key={item.id}
              onClick={() => {
                setSelectedCohort(item);
                onClickItem();
              }}
              disableRipple
              sx={{ width: "223px" }}
            >
              {item.title}
            </MenuItem>
          ))}
      </Menu>
      <Confirm
        isOpen={confirmOpen}
        title={`Add patients to cohort`}
        content={`Are you sure you want to add the selected patients to ${selectedCohort?.title}?`}
        onConfirm={onClickConfirm}
        onClose={onClickConfirmCancel}
      />
    </div>
  );
}
