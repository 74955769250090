import { ListButton, ShowButton, RaRecord } from "react-admin";

export interface RuneCancelButtonProps {
  buttonType: "LIST" | "SHOW";
  icon?: string;
  label: string;
  resource?: string;
  record?: RaRecord;
}
import { runeTheme } from "../../common/RuneTheme";

const RuneCancelButton = (props: RuneCancelButtonProps) => {
  if (props.buttonType === "LIST")
    return (
      <ListButton
        label={props.label}
        variant="contained"
        icon={<img src={props.icon} alt="" style={{ height: "24px" }} />}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "12px 16px",
          gap: "4px",
          height: "48px",
          boxShadow: "none",
          fontFamily: "Work Sans",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "16px",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
          background: runeTheme.palette.button.secondary.background,
          color: runeTheme.palette.button.secondary.text,
          borderColor: runeTheme.palette.button.secondary.border,
          border: "1px solid",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: runeTheme.palette.button.secondary.hoverBackground,
            boxShadow: "none"
          }
        }}
      />
    );
  return (
    <ShowButton
      resource={props.resource}
      record={props.record}
      label={props.label}
      variant="contained"
      icon={<img src={props.icon} alt="" style={{ height: "24px" }} />}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "12px 16px",
        gap: "4px",
        height: "48px",
        background: runeTheme.palette.background.light,
        borderRadius: "8px",
        border: "1px solid",
        borderColor: runeTheme.palette.primary.main,
        boxShadow: "none",
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        color: runeTheme.palette.primary.main,
        "&:hover": {
          backgroundColor: "#DBEEFB",
          boxShadow: "none"
        }
      }}
    />
  );
};

export default RuneCancelButton;
