import { useState } from "react";
import {
  Toolbar,
  BooleanInput,
  useUpdate,
  useRecordContext,
  useRedirect,
  useNotify,
  Labeled,
  TextInput,
  SimpleForm,
  Button,
  required,
  Identifier
} from "react-admin";

import {
  styled,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box
} from "@mui/material";

import mixpanel from "mixpanel-browser";

import iconCheckMark from "../../../shared/icon/checkmark.svg";
import SecondaryActionButton from "../../common/buttons/SecondaryActionButton";
import RuneSaveButton from "../../common/buttons/RuneSaveButton";
import { runeTheme } from "../../common/RuneTheme";
import { Device } from "../../../types";
import { TabIndex } from "../../../constants";

export function DeviceEditDialog(props: { patientId: Identifier }) {
  const { patientId } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button label="edit" onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClickClose}
        fullWidth
        sx={{ width: "500px", margin: "auto" }}
      >
        <DialogTitle>Edit Device</DialogTitle>
        <DialogContent>
          <DeviceEditForm
            handleClickClose={handleClickClose}
            patientId={patientId}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

function DeviceEditForm(props: {
  handleClickClose: () => void;
  patientId: Identifier;
}) {
  mixpanel.track("DeviceEditForm", { props: props });

  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();

  const { handleClickClose, patientId } = props;
  const deviceRecord = useRecordContext<Device>();

  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const DeviceEditSave = (data: any) => {
    mixpanel.track("DeviceEditSave", { props: props });

    const deviceId = deviceRecord.id;
    const { alias, disabled } = data;

    const patientDevicesPath = `/Patient/${patientId}/show/${TabIndex.PATIENT_TAB_DEVICES}`;

    update(
      "Device",
      { data: { deviceId, alias, disabled } },
      {
        onSuccess() {
          notify(`Device updated successfully.`);
          redirect(patientDevicesPath);
        },
        onError(error) {
          if (error instanceof Error) {
            notify(error.message, {
              type: "error"
            });
          } else {
            notify("Unknown error occurred. Unable to update device.", {
              type: "error"
            });
          }
        }
      }
    );
  };

  const Wrapper = styled("div")({
    boxShadow: "none"
  });

  return (
    <SimpleForm
      toolbar={DeviceEditToolbar({ handleClickClose })}
      onSubmit={DeviceEditSave}
      record={deviceRecord}
      component={Wrapper}
      sx={{ display: "block" }}
    >
      <Labeled label="DEVICE NAME (SHORT ID)">
        <Typography>{deviceRecord.deviceShortId}</Typography>
      </Labeled>

      <Labeled label="DEVICE TYPE">
        <Typography>{deviceRecord.deviceType.displayName}</Typography>
      </Labeled>

      <TextInput
        variant="outlined"
        source="alias"
        label="ALIAS"
        fullWidth
        validate={required()}
      />
      <Box display="flex" alignItems="top" width={"100%"}>
        <Box flexGrow={1}>
          <Labeled label="STATUS">
            <Typography>Enabled and collecting data</Typography>
          </Labeled>
        </Box>
        <Box>
          <BooleanInput
            source="disabled"
            label="Yes"
            format={(v) => !v}
            parse={(v) => !v}
            sx={{ paddingTop: "15px" }}
          />
        </Box>
      </Box>
    </SimpleForm>
  );
}

function DeviceEditToolbar(props: { handleClickClose: () => void }) {
  return (
    <Toolbar
      className="RuneFormBottomToolbar"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        margin: 0,
        background: runeTheme.palette.background.light
      }}
      disableGutters={true}
    >
      <SecondaryActionButton
        label={"Cancel"}
        onClick={props.handleClickClose}
      />
      <RuneSaveButton icon={iconCheckMark} label={"SAVE"} />
    </Toolbar>
  );
}
