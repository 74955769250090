import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildQueryDeviceParams {
  id: string;
  data: {
    patientId: string;
    deviceTypeId: string;
    deviceId: string;
    alias: string;
    disabled: boolean;
  };
}

interface Device {
  id: string;
  deviceShortId: string;
  alias: string;
  disabled: boolean;
  deviceType: {
    id: string;
    displayName: string;
  };
  createdAt: number;
}

const buildQueryDevice = (
  fetchType: string,
  params: BuildQueryDeviceParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.CREATE:
      return {
        query: gql`
          mutation createDevice($input: CreateDeviceInput!) {
            createDevice(input: $input) {
              device {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            patientId: params.data.patientId,
            deviceTypeId: params.data.deviceTypeId,
            alias: params.data.alias
          }
        },
        parseResponse: (response) => {
          const data = response.data.createDevice.device;

          return {
            data: data
          };
        }
      };
    case QueryTypes.UPDATE:
      return {
        query: gql`
          mutation updateDevice($input: UpdateDeviceInput!) {
            updateDevice(input: $input) {
              device {
                id
              }
            }
          }
        `,
        variables: {
          input: {
            deviceId: params.data.deviceId,
            deviceTypeId: params.data.deviceTypeId,
            disabled: params.data.disabled,
            alias: params.data.alias
          }
        },
        parseResponse: (response) => {
          const data = response.data.updateDevice.device;

          return {
            data: data
          };
        }
      };
    case QueryTypes.GET_MANY_REFERENCE:
      return {
        query: gql`
          query ($patientId: ID!, $cursor: Cursor) {
            patient(id: $patientId) {
              id
              deviceList(cursor: $cursor) {
                devices {
                  id
                  deviceShortId
                  alias
                  disabled
                  createdAt
                  deviceType {
                    id
                    displayName
                  }
                }
                pageInfo {
                  endCursor
                }
              }
            }
          }
        `,
        // TODO Implement pagination.
        variables: { patientId: params.id },
        parseResponse: (response) => {
          return {
            data: response.data.patient.deviceList.devices.map(
              (device: Device) => ({
                ...device
              })
            ),
            total: response.data.patient.deviceList.devices.length,
            endCursor: response.data.patient.deviceList.pageInfo.endCursor
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryDevice;
