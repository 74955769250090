import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import mixpanel from "mixpanel-browser";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh
} from "react-admin";
import {
  ListActions,
  ListContentDescription,
  ListContentHeader,
  ListContentTitle
} from "src/components/common/ListContentHeader";
import { runeTheme } from "src/components/common/RuneTheme";
import { StudyDatagridHeader } from "src/components/common/StudyDataGrid/StudyDatagridHeader";
import PrimaryActionButton from "src/components/common/buttons/PrimaryActionButton";
import iconCreateToken from "../../../shared/icon/create-token.svg";
import { BooleanInverseBadgeField } from "src/components/fields/BooleanInverseBadgeField";
import { useState } from "react";
import { useMutation } from "react-query";
import { TextField as MuiTextField } from "@mui/material";
import { UserAccessTokenEditDialog } from "./UserAccessTokenEdit";
import { ContentDetail } from "src/components/common/ContentHeader";

const CreateNewAccessTokenButton = () => {
  const [open, setOpen] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [tokenSecret, setTokenSecret] = useState("");
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const mutation = useMutation(
    () => dataProvider.create("UserAccessToken", { data: {} }),
    {
      onSuccess: (data) => {
        setTokenId(data.data.tokenId);
        setTokenSecret(data.data.tokenSecret);
        setOpen(true);
      },
      onError: (error: { message: string }) => {
        notify(
          `An error occurred, please try again later. Error: ${error.message}`,
          { type: "warning" }
        );
      }
    }
  );

  const handleClick = () => {
    mutation.mutate();
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  return (
    <>
      <PrimaryActionButton
        onClick={handleClick}
        icon={iconCreateToken}
        iconHeight="32px"
        label="Create Access Token"
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"New Access Token Created"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please copy the keys now because it is the only time they will be
            visible.
          </DialogContentText>
          <MuiTextField
            margin="dense"
            label="Token ID"
            type="text"
            fullWidth
            variant="outlined"
            value={tokenId}
            InputProps={{
              readOnly: true
            }}
          />
          <MuiTextField
            margin="dense"
            label="Token Secret"
            type="text"
            fullWidth
            variant="outlined"
            value={tokenSecret}
            InputProps={{
              readOnly: true
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const groupedLabels = [
  {
    subLabels: [{ name: "Access Token ID", sortable: true }]
  },
  { subLabels: [{ name: "Created on", sortable: true }] },
  { subLabels: [{ name: "Last used", sortable: true }] },
  { subLabels: [{ name: "Active", sortable: true }] }
];

const UserAccessTokenList = () => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric"
  };

  mixpanel.track("UserAccessTokenList");
  return (
    <Box sx={{ padding: "8px 32px" }}>
      <ListContentHeader>
        <ContentDetail>
          <ListContentTitle>User Access Tokens</ListContentTitle>
          <ListContentDescription>
            User Access Tokens are used to interact with our data platform
            through the API or our RuneQ SDK. For more information, please visit{" "}
            <a
              href="https://runeq.readthedocs.io/en/latest/pages/quickstart.html#api-credentials"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://runeq.readthedocs.io/
            </a>
            . <br />
            As a security best practice, these tokens should never be shared and
            should be rotated on a regular basis.
          </ListContentDescription>
        </ContentDetail>

        <ListActions>
          <CreateNewAccessTokenButton />
        </ListActions>
      </ListContentHeader>
      <List perPage={50} actions={false}>
        <Datagrid
          header={<StudyDatagridHeader groupedLabels={groupedLabels} />}
          bulkActionButtons={false}
          sx={{
            "& .RaDatagrid-headerCell": {
              /* Table column header */
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "-0.03em",
              textTransform: "capitalize",
              color: runeTheme.palette.primary.main
            }
          }}
        >
          <TextField source="accessTokenId" label="Access Token ID" />
          <DateField
            source="createdAt"
            label="Created on"
            options={dateOptions}
          />
          {/* TODO: Uncomment this when the field `usedAt` is fixed */}
          {/* <DateField
            source="usedAt"
            label="Last used"
            options={dateOptions}
            emptyText="Never used"
          /> */}
          <BooleanInverseBadgeField label="Active" source="disabled" />
          <UserAccessTokenEditDialog />
        </Datagrid>
      </List>
    </Box>
  );
};

export default UserAccessTokenList;
