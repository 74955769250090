import { Box, Typography, styled } from "@mui/material";
import mixpanel from "mixpanel-browser";
import deviceIDScreenshot1 from "../shared/images/strivepd_device_id_1.png";
import deviceIDScreenshot2 from "../shared/images/strivepd_device_id_2.png";
import deviceIDScreenshot3 from "../shared/images/strivepd_device_id_3.png";
import deviceIDScreenshot4 from "../shared/images/strivepd_device_id_4.png";

const GridContainer = styled("div")({
  display: "grid",
  width: "100px",
  gridTemplateColumns: "repeat(4, 1fr)",
  columnGap: "20px"
});

const GridCol = styled("div")({
  display: "flex",
  width: "280px",
  flexDirection: "column"
});

export const HelpPage = () => {
  mixpanel.track("HelpPage");
  return (
    <Box sx={{ padding: "8px 32px" }}>
      <h1>Frequently Asked Questions</h1>
      <h2>Why don't I see the projects I am looking for?</h2>
      <p>
        The projects listed will be based on your current organization. If your
        Rune Labs credentials allow you to access multiple organizations, you
        can switch your current organization in the Administration tab. Once
        done, refresh the StriveStudy Project page.
      </p>
      <h2>How do I invite other team members to my current organization?</h2>
      <p>
        User management is handled by the Rune Labs Support team. Please reach
        out to them directly to request access for new users, at{" "}
        <a href="mailto:support@runelabs.io">support@runelabs.io</a>.
      </p>
      <h2>
        <p>
          Where can I find the Device ID of a patient I want to add to my
          project?
        </p>
      </h2>
      <p>
        On the StrivePD iPhone app of the patient, go to settings (gear icon)
        &gt; Watch &gt; Pairing info &gt; Phone/Watch device id. Any of these
        device IDs will allow to add this patient to the project or org. You can
        follow these steps:
      </p>

      <GridContainer>
        <GridCol>
          <img src={deviceIDScreenshot1} style={{ paddingBottom: "10px" }} />
          <Typography>
            Start by tapping the cog icon, for your app settings.
          </Typography>
        </GridCol>
        <GridCol>
          <img src={deviceIDScreenshot2} style={{ paddingBottom: "10px" }} />
          <Typography>
            Next, inside 'My Settings' tap on 'Device settings'.
          </Typography>
        </GridCol>
        <GridCol>
          <img src={deviceIDScreenshot3} style={{ paddingBottom: "10px" }} />
          <Typography>In 'Device Settings' tap 'Rune settings'.</Typography>
        </GridCol>
        <GridCol>
          <img src={deviceIDScreenshot4} style={{ paddingBottom: "10px" }} />
          <Typography>
            Finally, locate the 'Phone Device ID' or 'Watch Device ID'.
          </Typography>
        </GridCol>
      </GridContainer>
    </Box>
  );
};
