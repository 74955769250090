import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildQueryOrgTagsParams {
  data: {
    ids: string[];
  };
}

const buildQueryOrgTags = (
  fetchType: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params: BuildQueryOrgTagsParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.GET_MANY:
      return {
        query: gql`
          query getOrgTags {
            orgTags
          }
        `,
        parseResponse: (response) => {
          return {
            data: response.data.orgTags.map((tag: string) => ({
              // We use the tag for both id and name as OrgCreate's CheckboxGroupInput
              // expects both, and passes the id as is to the org create call,
              // which expects the tag name.
              id: tag,
              name: tag
            }))
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryOrgTags;
