import { Datagrid, TextField, ReferenceManyField } from "react-admin";

import { RuneDateField } from "../../fields/RuneDateField";
import { BooleanInverseBadgeField } from "../../fields/BooleanInverseBadgeField";
import { StudyDatagridHeader } from "../../common/StudyDataGrid/StudyDatagridHeader";
import { MemberStatus } from "./MemberShow/MemberStatus";
import { MemberRole } from "./MemberShow/MemberRole";
import { InviteMemberDialog } from "./MemberInvite";
import { NoResourceMessage } from "../../common/NoResourceMessage";

const NoMemberMessage = () => (
  <NoResourceMessage>No members.</NoResourceMessage>
);

export const InviteMemberButton = () => {
  return <InviteMemberDialog></InviteMemberDialog>;
};

const MemberList = () => {
  const dateFmtOptions = { year: "numeric", month: "short", day: "numeric" };

  const groupedLabels = [
    { subLabels: [{ name: "Display Name", sortable: true }] },
    { subLabels: [{ name: "Email", sortable: true }] },
    { subLabels: [{ name: "User ID", sortable: true }] },
    { subLabels: [{ name: "Role", sortable: true }] },
    { subLabels: [{ name: "Date Invited", sortable: true }] },
    { subLabels: [{ name: "Status", sortable: true }] },
    { subLabels: [{ name: "Enabled", sortable: true }] }
  ];

  return (
    <ReferenceManyField
      label="Members"
      reference={"Member"}
      target={"member_id"}
    >
      <Datagrid
        rowClick="show"
        empty={<NoMemberMessage />}
        header={<StudyDatagridHeader groupedLabels={groupedLabels} />}
        bulkActionButtons={false}
      >
        <TextField source="user.displayName" label="Display Name" />
        <TextField source="user.email" label="Email" />
        <TextField source="user.id" label="User ID" />
        <MemberRole source="role.displayName" label="Role" />

        <RuneDateField
          source="status.invitedAt"
          label="Date Invited"
          showTime={true}
          dateFmtOptions={dateFmtOptions}
        />

        <MemberStatus source="status.displayName" label="Status" />
        <BooleanInverseBadgeField source="disabled" label="Enabled" />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default MemberList;
