import { Show } from "react-admin";

import mixpanel from "mixpanel-browser";

import MemberHeader from "./MemberHeader";
import MemberTabs from "./MemberTabs";

const MemberShow = () => {
  mixpanel.track("MemberShow");

  return (
    <Show
      title="Member Details"
      emptyWhileLoading
      actions={false}
      component="div"
    >
      <MemberHeader />
      <MemberTabs />
    </Show>
  );
};

export default MemberShow;
