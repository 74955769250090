import React from "react";
import { TextField } from "react-admin";
import { useLocation } from "react-router-dom";
import { Typography, Breadcrumbs, styled } from "@mui/material";

import { getTabIndex } from "../../../StudyHeader";

import {
  ContentHeaderContainer,
  ContentDetail,
  ContentDetailTopRow,
  ContentDetailTopLine,
  ContentDetailMiddleRow,
  ContentDetailLastRow
} from "../../../common/ContentHeader";

import { RuneDateField } from "../../../fields/RuneDateField";
import { Badge } from "../../../fields/Badge";
import { runeTheme } from "../../../common/RuneTheme";
import { InviteMemberButton } from "../../member/MemberList";
import { TabIndex } from "../../../../constants";

import { AddPatientDialog } from "../../patient/PatientAddToOrg";

const ListActions = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "16px"
});

const AddPatientButton = () => {
  return <AddPatientDialog></AddPatientDialog>;
};

const OrgHeader = () => {
  const dateOptions = { year: "numeric", month: "short", day: "numeric" };

  // Dynamically update the header based on selected tab
  let selectedTab;
  const location = useLocation();

  switch (getTabIndex(location.pathname)) {
    case TabIndex.ORG_TAB_PATIENTS:
      selectedTab = "Patients";
      break;
    case TabIndex.ORG_TAB_MEMBERS:
      selectedTab = "Members";
      break;
    default:
      selectedTab = "Patients";
  }

  return (
    <ContentHeaderContainer>
      <ContentDetail>
        <ContentDetailTopRow>
          <ContentDetailTopLine>
            ID: <TextField source="id" sx={{ fontSize: "1.2rem" }} />
          </ContentDetailTopLine>
          <Badge bgColor={runeTheme.palette.badge.default} display="flex">
            Default
          </Badge>
        </ContentDetailTopRow>

        <ContentDetailMiddleRow>
          <Breadcrumbs>
            <TextField
              source="displayName"
              variant="h3"
              sx={{ fontWeight: "300" }}
            />

            <Typography variant="h3">{selectedTab}</Typography>
          </Breadcrumbs>
        </ContentDetailMiddleRow>

        <ContentDetailLastRow>
          Created on{" "}
          <RuneDateField
            source="created"
            sx={{ fontWeight: "900" }}
            dateFmtOptions={dateOptions}
          />
        </ContentDetailLastRow>
      </ContentDetail>

      <ListActions>
        {selectedTab === "Members" ? (
          <InviteMemberButton />
        ) : (
          <AddPatientButton />
        )}
      </ListActions>
    </ContentHeaderContainer>
  );
};

export default OrgHeader;
