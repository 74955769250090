import React, { useState } from "react";
import {
  SimpleForm,
  TextInput,
  Toolbar,
  Button,
  required,
  Edit,
  useRefresh,
  useNotify,
  DeleteWithConfirmButton,
  useRedirect,
  useRecordContext
} from "react-admin";

import { styled, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import iconConfirm from "../../../shared/icon/confirm.svg";
import { runeTheme } from "../../common/RuneTheme";
import RuneSaveButton from "../../common/buttons/RuneSaveButton";

const Wrapper = styled("div")({
  boxShadow: "none"
});

function EditCohortToolbar(props: {
  cohortId: string;
  handleClickClose: () => void;
}) {
  const { cohortId, handleClickClose } = props;
  const redirect = useRedirect();
  const record = useRecordContext();
  const notify = useNotify();

  return (
    <Toolbar
      sx={{
        background: runeTheme.palette.background.light,
        display: "flex",
        justifyContent: "space-between"
      }}
      disableGutters={true}
    >
      <DeleteWithConfirmButton
        resource={"Cohort"}
        id={cohortId}
        confirmTitle={"Delete cohort"}
        confirmContent="You will not be able to recover this cohort. Are you sure?"
        variant="outlined"
        redirect={false}
        sx={{
          padding: "12px 16px",
          height: "48px",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "16px",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
          borderRadius: "8px",
          borderColor: runeTheme.palette.button.destructive.border,
          "&:hover": {
            borderColor: runeTheme.palette.button.destructive.hoverBorder,
            boxShadow: "none"
          }
        }}
        mutationOptions={{
          onSuccess() {
            notify("Cohort deleted.", {
              type: "info"
            });
            redirect("show", "Project", record.id);
          },
          onError() {
            notify("An error occurred. Unable to delete cohort.", {
              type: "error"
            });
          }
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
          backgroundColor: runeTheme.palette.background.light
        }}
      >
        <Button
          sx={{
            padding: "12px 16px",
            height: "48px",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "16px",
            letterSpacing: "0.1em",
            textTransform: "uppercase",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: runeTheme.palette.button.secondary.border,
            color: runeTheme.palette.button.secondary.text,
            "&:hover": {
              backgroundColor:
                runeTheme.palette.button.secondary.hoverBackground
            }
          }}
          onClick={handleClickClose}
          label={"Cancel"}
        />
        <RuneSaveButton icon={iconConfirm} label={"SAVE"} />
      </Box>
    </Toolbar>
  );
}

function EditCohortForm(props: {
  cohortId: string;
  handleClickClose: () => void;
}) {
  const { cohortId, handleClickClose } = props;
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <Edit
      id={cohortId}
      resource="Cohort"
      component={Wrapper}
      sx={{
        "& .RaEdit-main": {
          boxShadow: "none",
          borderRadius: "8px"
        }
      }}
      redirect={false}
      mutationOptions={{
        onSuccess() {
          handleClickClose();
          refresh();
          notify(`Cohort updated`, {
            type: "info",
            multiLine: true
          });
        }
      }}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={EditCohortToolbar({ cohortId, handleClickClose })}
        resource={"Cohort"}
        component={Wrapper}
      >
        <TextInput
          variant="outlined"
          source="title"
          id="title"
          label="Name"
          fullWidth
          validate={required()}
        />
        <TextInput
          variant="outlined"
          source="description"
          multiline={true}
          label="Description"
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
}

export default function EditCohortDialog(props: { cohortId: string }) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{
          display: "flex",
          padding: "16px",
          gap: "0px",
          height: "20px",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "20px",
          letterSpacing: "-0.03em",
          textTransform: "capitalize",
          color: runeTheme.palette.primary.main,
          margin: "10px"
        }}
        onClick={handleClickOpen}
        label={"Edit Cohort"}
      >
        <EditIcon />
      </Button>
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Edit cohort</DialogTitle>
        <DialogContent>
          <EditCohortForm
            cohortId={props.cohortId}
            handleClickClose={handleClickClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
