import { forwardRef } from "react";
import { Link, LinkProps } from "react-admin";
import { Button } from "@mui/material";
import { runeTheme } from "../../common/RuneTheme";
import { kebabCase } from "lodash";

export interface PrimaryActionButtonProps {
  iconHeight?: string;
  link?: string;
  state?: string | Record<string, unknown>;
  icon: string;
  label: string;
  onClick?: () => void;
}

// Disabling warning as actually using ref in Link component triggers other console warning
// eslint-disable-next-line  @typescript-eslint/no-unused-vars
const ForwardedLink = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link {...props} onClick={props.onClick} />
));

const PrimaryActionButton = (props: PrimaryActionButtonProps) => {
  return (
    <Button
      component={props.link ? ForwardedLink : "button"}
      variant="contained"
      id={kebabCase(props.label + "-primary-button")}
      to={{
        pathname: props.link
      }}
      state={props.state}
      onClick={props.onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "12px 16px",
        gap: "4px",
        minWidth: "196px",
        height: "48px",
        borderRadius: "8px",
        fontWeight: "600",
        color: runeTheme.palette.button.primary.text,
        background: runeTheme.palette.button.primary.background,
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          color: runeTheme.palette.button.primary.hoverText,
          background: runeTheme.palette.button.primary.hoverBackground
        }
      }}
    >
      <img
        src={props.icon}
        alt=""
        style={{
          height: props.iconHeight ? props.iconHeight : "24px",
          padding: "3px 5px"
        }}
      />
      <span>{props.label}</span>
    </Button>
  );
};

export default PrimaryActionButton;
