import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface DeviceType {
  id: string;
  displayName: string;
}

const buildQueryDeviceTypes = (fetchType: string): BuildQueryResult => {
  switch (fetchType) {
    // Get all Device Types
    case QueryTypes.GET_MANY:
      return {
        query: gql`
          query deviceTypes {
            deviceTypes {
              id
              displayName
            }
          }
        `,
        // TODO Implement pagination.
        parseResponse: (response) => {
          return {
            data: response.data.deviceTypes.map((deviceType: DeviceType) => ({
              ...deviceType
            })),
            total: response.data.deviceTypes.length
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryDeviceTypes;
