import {
  useFlags as useLDFlags,
  useLDClient
} from "launchdarkly-react-client-sdk";
import { useGetIdentity } from "react-admin";
import { useEffect } from "react";

import { normalizeId } from "../utilities";

interface Flags {
  cohortTabsVisible: boolean;
  patientDataAvailabilityTabVisible: boolean;
  patientStartEndDate: boolean;
  displayExternalLinks: boolean;
  // other LD flags...
}

// Wrapper to set launch darkly identity context.
export function useFlags(): Flags {
  const ldClient = useLDClient();
  const { data: user } = useGetIdentity();
  let id = "";

  if (user?.id) {
    id = normalizeId(user.id.toString());
  }

  // Ensure user resolves before calling LD identify, or LD will error with Invalid Context
  useEffect(() => {
    if (user) {
      ldClient?.identify({
        key: id,
        name: user.name,
        email: user.email
      });
    }
  }, [user, ldClient, id]);

  return useLDFlags();
}
