import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildQueryStreamParams {
  meta: {
    nextPageCursor: string;
    filters: {
      patientId: string;
      streamTypeId: string;
      parameters: [
        {
          key: string;
          value: string;
        }
      ];
    };
  };
}

const buildQueryStream = (
  fetchType: string,
  params: BuildQueryStreamParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.GET_LIST:
      return {
        query: gql`
          query getStreamList($filters: StreamQueryFilters) {
            streamList(filters: $filters) {
              streams {
                id
                maxTime
                minTime
                deviceId
                streamType {
                  id
                  name
                  description
                }
                streamTypeId
                algorithm
                parameters {
                  key
                  value
                }
              }
              pageInfo {
                endCursor
              }
            }
          }
        `,
        variables: {
          cursor: params.meta.nextPageCursor,
          filters: params.meta.filters
        },
        parseResponse: (response) => {
          return {
            data: response.data.streamList.streams,
            total: response.data.streamList.streams.length,
            endCursor: response.data.streamList.pageInfo.endCursor
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryStream;
