import { Box } from "@mui/material";
import mixpanel from "mixpanel-browser";

export const DocumentationPage = () => {
  mixpanel.track("DocumentationPage");
  return (
    <Box sx={{ padding: "8px 32px" }}>
      <h1>Documentation</h1>
      <h2>Create Project</h2>
      <p>
        To create a project, you must provide a name and a select a Project
        Type. The start date is set by default to the current date. These
        settings may be edited later, on the Project Detail page.
      </p>
      <h2>Project Detail</h2>
      <p>
        The Project Detail page displays a list of patients in the project, with
        associated QC metrics. The aggregated QC metrics allow you to quickly
        identify patients that have not uploaded data recently.
      </p>
      <h2>Add Patient to Project</h2>
      <p>
        Use the device ID of a patient to add them to a project. The default
        patient codename is randomly generated, but it can be overwritten to
        respect study protocols.
      </p>
      <h2>Data Export</h2>
      <p>
        To export data from a project, it must be configured by Rune Labs.
        Please reach out to the Support Team for assistance, at{" "}
        <a href="mailto:support@runelabs.io">support@runelabs.io</a>.
      </p>
      <p>
        Once a project has been configured for data export, you can generate a
        new export or download a previously generated export from the Project
        Detail page.
      </p>
    </Box>
  );
};
