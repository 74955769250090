import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildQueryPatientAccessParams {
  data: {
    orgId: string;
    deviceId?: string;
    patientId?: string;
  };
}

const buildQueryPatientAccess = (
  fetchType: string,
  params: BuildQueryPatientAccessParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.CREATE:
      return {
        query: gql`
          mutation ($input: CreatePatientAccessInput!) {
            createPatientAccess(input: $input) {
              patientAccess {
                id
                org {
                  id
                }
                patient {
                  id
                  codeName
                  deviceList {
                    devices {
                      id
                      deviceShortId
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          input: {
            orgId: params.data.orgId,
            deviceId: params.data.deviceId,
            patientId: params.data.patientId
          }
        },
        parseResponse: (response) => {
          return {
            data: response.data.createPatientAccess.patientAccess
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryPatientAccess;
