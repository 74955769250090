import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildReferralCodeParams {
  id: string;
  data: {
    code: string;
    appModeId: number;
    projectId: string;
    organizationId: string;
  };
}

const buildQueryReferralCode = (
  fetchType: string,
  params: BuildReferralCodeParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.CREATE:
      return {
        query: gql`
          mutation createReferralCode($input: CreateReferralCodeInput!) {
            createReferralCode(input: $input) {
              code
            }
          }
        `,
        variables: {
          input: {
            code: params.data.code,
            appModeId: params.data.appModeId,
            projectId: params.data.projectId,
            organizationId: params.data.organizationId
          }
        },
        parseResponse: (response) => {
          return {
            data: {
              id: response.data?.createReferralCode?.code,
              errors: response.errors
            }
          };
        }
      };
    case QueryTypes.DELETE:
      return {
        query: gql`
          mutation ($input: RemoveReferralCodeInput!) {
            removeReferralCode(input: $input) {
              success
            }
          }
        `,
        variables: {
          input: {
            code: params.id
          }
        },
        parseResponse: (response) => {
          return {
            data: {
              id: params.id,
              errors: response.errors
            }
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryReferralCode;
