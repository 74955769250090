import { useEffect } from "react";
import { useGetIdentity } from "react-admin";

const LogoutOnTabClose = () => {
  const { data: user } = useGetIdentity();
  const isSuperAdmin = user?.defaultMembership?.role.superAdmin;

  useEffect(() => {
    const handleTabClose = () => {
      // When a user sets their current org, a page reload gets triggered, which counts as a
      // `beforeunload` event. This would cause a super admin to get logged out, which is only desired
      // for tab closes, and not page reloads.
      // To avoid this, we store a flag in localStorage to control the desired beahvior.
      const reloadFlag = window.localStorage.getItem("reloadFlag");
      if (isSuperAdmin && reloadFlag !== "true") {
        // The localStorage has to be cleared instead of calling logout() because
        // logout() does not resolve prior to the tab being closed.
        window.localStorage.clear();
      } else {
        window.localStorage.removeItem("reloadFlag");
      }
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return null;
};

export default LogoutOnTabClose;
