import { makeStyles } from "@mui/styles";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import {
  Create,
  DataProvider,
  Datagrid,
  DateField,
  FieldProps,
  FunctionField,
  ListContextProvider,
  NotificationType,
  SelectInput,
  SimpleForm,
  TextField,
  required,
  useDataProvider,
  useList,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";

import DownloadIcon from "@mui/icons-material/Download";
import mixpanel from "mixpanel-browser";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled
} from "@mui/material";
import SecondaryActionButton from "src/components/common/buttons/SecondaryActionButton";
import iconReport from "../../../../shared/icon/report.svg";
import { Project } from "../../../../types";

const Wrapper = styled("div")({
  boxShadow: "none",
  width: "100%",
  backgroundColor: "#EFF4FA"
});

const useStyles = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    padding: "20px 0 0",
    alignItems: "flex-start"
  },
  submitButton: {
    whiteSpace: "nowrap"
  },
  monthSelector: {
    width: "200px",
    marginRight: "20px",
    marginTop: 0
  }
});

interface MonthOption {
  id: string;
  name: string;
  disabled: boolean;
}

interface MonthSelectorProps extends FieldProps {
  source: string;
  label: string;
  projectStartDate: string;
  isEndMonth: boolean;
  startMonth?: string;
  onChange?: (month: string) => void;
  className?: string;
}

function MonthSelector({
  source,
  label,
  projectStartDate,
  isEndMonth,
  startMonth,
  onChange,
  className
}: MonthSelectorProps) {
  const startDate = moment(projectStartDate);
  const startMonthMoment = moment(startMonth);
  const today = moment();
  const months: MonthOption[] = [];
  const current = startDate;

  while (current <= today) {
    const isMonthEnabled =
      !isEndMonth ||
      current.isBetween(
        startMonth,
        startMonthMoment.clone().add(2, "months"),
        "month",
        "[]"
      );
    months.push({
      id: current.format("YYYY-MM"),
      name: current.format("MMMM YYYY"),
      disabled: !isMonthEnabled
    });
    current.add(1, "month");
  }

  return (
    <SelectInput
      disabled={isEndMonth && !startMonth}
      variant="outlined"
      source={source}
      label={label}
      className={className}
      choices={months}
      validate={required()}
      onChange={(event) => {
        if (onChange) {
          onChange(event.target.value);
        }
      }}
    />
  );
}

function CreateExportForm(props: { handleClickClose: () => void }) {
  const { handleClickClose } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const project = useRecordContext<Project>();
  const classes = useStyles();
  const [startMonth, setStartMonth] = useState<string | undefined>();
  const [endMonth, setEndMonth] = useState<string | undefined>();

  return (
    <Create
      resource="DataExport"
      component={Wrapper}
      sx={{
        "& .RaCreate-main": {
          boxShadow: "none",

          backgroundColor: "#EFF4FA",
          padding: "20px 20px 0",
          width: "100%",
          marginBottom: "50px"
        },
        "&&": {
          // Target the form controls
          width: "700px"
        }
      }}
      redirect={false}
      mutationOptions={{
        meta: { projectId: project.id },
        onSuccess() {
          mixpanel.track("CreateDataExportSuccessful");
          handleClickClose();
          refresh();
          notify(
            "Export created successfully - an email notification will be sent when it is ready to download.",
            { type: "success" }
          );
        },
        onError() {
          mixpanel.track("CreateDataExportFailed");
          handleClickClose();
          refresh();
          notify("Export failed.", { type: "error" });
          refresh();
        }
      }}
    >
      <SimpleForm resource={"DataExport"} component={Wrapper} toolbar={false}>
        <Typography variant="h4" gutterBottom>
          Generate a new Data Export
        </Typography>
        <Box className={classes.formContainer}>
          <MonthSelector
            source="startMonth"
            label="Start Month"
            projectStartDate={project.startedAt}
            isEndMonth={false}
            onChange={setStartMonth}
            className={classes.monthSelector}
          />
          <MonthSelector
            source="endMonth"
            label="End Month"
            projectStartDate={project.startedAt}
            isEndMonth={true}
            startMonth={startMonth}
            onChange={setEndMonth}
            className={classes.monthSelector}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={!startMonth || !endMonth}
          >
            SUBMIT DATA EXPORT
          </Button>
        </Box>
      </SimpleForm>
    </Create>
  );
}

type NotifyFunction = (
  message: string,
  options?: NotificationOptions & { type?: NotificationType }
) => void;

const handleDownloadClick = async (
  id: string | number,
  notify: NotifyFunction,
  dataProvider: DataProvider
): Promise<void> => {
  try {
    const { data } = await dataProvider.getOne("DataExport", { id });
    const presignedUrl = data.presignedUrl; // Assuming the response contains a presignedUrl field
    // Trigger the download
    const link = document.createElement("a");
    link.href = presignedUrl;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Error fetching DataExport details:", error);
    notify("Error fetching DataExport details.", { type: "error" });
  }
};

const DownloadIconButton = ({
  record
}: {
  record: {
    status: string;
    id: string | number;
  };
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  if (record.status === "AVAILABLE") {
    return (
      <IconButton
        onClick={() => handleDownloadClick(record.id, notify, dataProvider)}
      >
        <DownloadIcon />
      </IconButton>
    );
  } else {
    return <></>;
  }
};

function DataExportList({ isOpen }: { isOpen: boolean }) {
  const [listContext, setListContext] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    isLoading: boolean;
  }>({ data: [], isLoading: false });
  const dataProvider = useDataProvider();
  const project = useRecordContext<Project>();

  useEffect(() => {
    if (isOpen) {
      // Fetch data only when the dialog is open
      const fetchData = async () => {
        try {
          setListContext((prevState) => ({ ...prevState, isLoading: true }));
          const { data } = await dataProvider.getList("DataExport", {
            filter: { projectId: project.id },
            sort: { field: "created_at", order: "DESC" },
            pagination: { page: 1, perPage: 50 }
          });
          setListContext({ data, isLoading: false });
        } catch (error) {
          console.error("Error fetching DataExport list:", error);
          setListContext((prevState) => ({ ...prevState, isLoading: false }));
        }
      };

      fetchData();
    }
  }, [isOpen, dataProvider, project.id]); // Depend on isOpen to trigger refresh

  const listContextDataGrid = useList({
    data: listContext.data,
    isLoading: listContext.isLoading
  });

  return (
    <>
      <Typography variant="h4">Previously generated Data Exports</Typography>
      <ListContextProvider value={listContextDataGrid}>
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            render={(record: { status: string; id: string | number }) => (
              <DownloadIconButton record={record} />
            )}
            label="Download"
          />
          <DateField source="createdAt" label="Created At" showTime />
          <TextField source="createdBy.email" label="By" />
          <TextField source="startMonth" label="Start Month" />
          <TextField source="endMonth" label="End Month" />
          <TextField source="status" label="Status" />
          <TextField source="dataExportSha256" label="SHA256" />
        </Datagrid>
      </ListContextProvider>
    </>
  );
}

interface DataExportDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function DataExportDialog({ isOpen, onClose }: DataExportDialogProps) {
  const [open, setOpen] = useState(isOpen);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <SecondaryActionButton
        label={"Data Export"}
        icon={
          <img
            src={iconReport}
            alt=""
            style={{ height: "24px", padding: "3px 5px" }}
          />
        }
        onClick={handleClickOpen}
      />
      <Dialog open={open} onClose={handleClickClose} fullWidth maxWidth={false}>
        <DialogTitle>Data Export</DialogTitle>

        <DialogContent>
          <CreateExportForm handleClickClose={handleClickClose} />
          <DataExportList isOpen={open} />
        </DialogContent>
      </Dialog>
    </>
  );
}
