import React, { useState } from "react";
import { Button, CardContent } from "@mui/material";

import {
  Login,
  useLogin,
  TextInput,
  Form,
  required,
  useNotify
} from "react-admin";

import { DUO_PREFIX, RUNE_SUFFIX } from "../constants";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [usePassword, setUsePassword] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleLoginError = (error: any) => {
    notify(error.message, { type: "error" });
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleUsernameSubmit = (data: any) => {
    // If user is an admin, use SSO. Else use password.
    const username = data.username;
    if (
      (username.startsWith(DUO_PREFIX) ||
        username.startsWith(DUO_PREFIX.toLocaleLowerCase())) &&
      username.endsWith(RUNE_SUFFIX)
    ) {
      login({ username: "admin" }).catch((error) => {
        handleLoginError(error);
      });
    } else {
      setUsername(username);
      setUsePassword(true);
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleSignIn = (data: any) => {
    login({ username: data.username, password: data.password }).catch(
      (error) => {
        handleLoginError(error);
      }
    );
  };

  return (
    <Login
      backgroundImage="strivestudy_login.png"
      sx={{
        backgroundPosition: "center",
        "& .RaLogin-card": {
          marginTop: "16em",
          boxShadow:
            "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)"
        }
      }}
    >
      <CardContent sx={{ width: "300px" }}>
        {!usePassword && (
          <Form onSubmit={handleUsernameSubmit}>
            <TextInput
              id="username"
              autoFocus
              source="username"
              autoComplete="username"
              validate={required()}
              fullWidth
            />
            <Button variant="contained" type="submit" fullWidth>
              Submit
            </Button>
          </Form>
        )}
        {usePassword && (
          <Form onSubmit={handleSignIn}>
            <TextInput
              id="username"
              autoFocus
              source="username"
              autoComplete="username"
              defaultValue={username}
              validate={required()}
              fullWidth
            />
            <TextInput
              id="password"
              source="password"
              type="password"
              autoComplete="current-password"
              validate={required()}
              fullWidth
            />
            <Button variant="contained" type="submit" fullWidth>
              Sign In
            </Button>
          </Form>
        )}
      </CardContent>
    </Login>
  );
};

export default LoginPage;
