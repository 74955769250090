import React from "react";
import ReactDOM from "react-dom/client";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

/**
 * Asynchronous entrypoint.
 *
 * React is only initialized once feature flag provider is connected.
 */
(async () => {
  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const { launchdarkly = {} } = (window as any).Rune.Carrot.config;

  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchdarkly && launchdarkly.clientSideId,
    options: { streaming: false }
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <LDProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </LDProvider>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
