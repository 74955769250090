/**
 * Returns a sanitized id.
 *
 * @example
 * // Returns 123456789
 * normalizeId("user", "user-123456789,user")
 *
 * @param id - Raw id from dynamodb with the format resource-<id>,resource
 * @returns The numeric portion of the raw id
 */
export function normalizeId(id: string): string {
  const dashArr = id.split("-");
  if (dashArr.length === 1) {
    return dashArr[0];
  }

  const commaArr = dashArr[1].split(",");
  return commaArr[0];
}
