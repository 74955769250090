import React from "react";
import {
  TextField,
  Link,
  useRecordContext,
  useCreatePath,
  useGetIdentity
} from "react-admin";
import { useLocation } from "react-router-dom";

import { Breadcrumbs, styled } from "@mui/material";

import { RuneDateField } from "../../../fields/RuneDateField";
import { getTabIndex } from "../../../StudyHeader";

import {
  ContentHeaderContainer,
  ContentDetail,
  ContentDetailTopRow,
  ContentDetailTopLine,
  ContentDetailMiddleRow,
  ContentDetailLastRow
} from "../../../common/ContentHeader";
import { useFlags } from "src/clients/launchDarkly";
import { PatientIdLinkField } from "src/components/fields/PatientIdLinkField";
import iconExport from "../../../../shared/icon/export.svg";

const PatientListActions = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "16px"
});

const PatientHeader = () => {
  const record = useRecordContext();
  const orgID = record.org?.id;

  const { data: user } = useGetIdentity();

  const isAdmin =
    user?.defaultMembership?.role.admin ||
    user?.defaultMembership?.role.superAdmin;

  const createPath = useCreatePath();

  const dateOptions = { year: "numeric", month: "short", day: "numeric" };

  let selectedTab;
  const location = useLocation();

  switch (getTabIndex(location.pathname)) {
    case "1":
      selectedTab = "Devices";
      break;
    default:
      selectedTab = "Overview";
  }

  return (
    <ContentHeaderContainer>
      <ContentDetail>
        <ContentDetailTopRow>
          <ContentDetailTopLine>
            Patient ID: <TextField source="id" sx={{ fontSize: "1.2rem" }} />
            {isAdmin && (
              <PatientIdLinkField
                icon={iconExport}
                tooltip="Open in StrivePD portal"
              />
            )}
          </ContentDetailTopLine>
        </ContentDetailTopRow>

        <ContentDetailMiddleRow>
          <Breadcrumbs>
            <TextField
              source="org.displayName"
              variant="h3"
              sx={{ fontWeight: "300" }}
            />
            <Link to={createPath({ resource: "org", type: "show", id: orgID })}>
              Patients
            </Link>
            <TextField
              source="codeName"
              variant="h3"
              sx={{ fontWeight: "400" }}
            />
          </Breadcrumbs>
        </ContentDetailMiddleRow>

        <ContentDetailLastRow>
          Created on{" "}
          <RuneDateField
            source="createdAt"
            sx={{ fontWeight: "900" }}
            dateFmtOptions={dateOptions}
          />
        </ContentDetailLastRow>
      </ContentDetail>
      {selectedTab === "Devices" ? (
        <PatientListActions>
          {/* Previous section for adding DBS device button */}
          {/* Keeping this here for future use */}
        </PatientListActions>
      ) : null}
    </ContentHeaderContainer>
  );
};

export default PatientHeader;
