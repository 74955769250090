import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildQueryOrgParams {
  data: {
    displayName: string;
    orgTags: string[];
  };
  meta: {
    nextPageCursor: string;
  };
}

const buildQueryOrg = (
  fetchType: string,
  params: BuildQueryOrgParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.GET_LIST:
      return {
        query: gql`
          query ($cursor: Cursor, $limit: Int) {
            orgList(cursor: $cursor, limit: $limit) {
              pageInfo {
                endCursor
              }
              orgs {
                id
                displayName
                orgTags
                created
              }
            }
          }
        `,
        // TODO Implement pagination.
        variables: { cursor: params.meta.nextPageCursor },
        parseResponse: (response) => {
          return {
            data: response.data.orgList.orgs,
            total: response.data.orgList.orgs.length,
            endCursor: response.data.orgList.pageInfo.endCursor
          };
        }
      };
    case QueryTypes.GET_ONE:
      return {
        query: gql`
          query {
            org {
              id
              displayName
              orgTags
              created
            }
          }
        `,
        parseResponse: (response) => {
          return { data: response.data.org };
        }
      };
    case QueryTypes.CREATE:
      return {
        query: gql`
          mutation ($input: CreateOrgInput!) {
            createOrg(input: $input) {
              org {
                id
                displayName
                orgTags
              }
            }
          }
        `,
        variables: {
          input: {
            displayName: params.data.displayName,
            orgTags: params.data.orgTags
          }
        },
        parseResponse: (response) => {
          return { data: response.data.createOrg.org };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryOrg;
