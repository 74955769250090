import {
  Datagrid,
  TextField,
  ReferenceManyField,
  useRecordContext,
  useGetIdentity
} from "react-admin";

import { DeviceEditDialog } from "./DeviceEdit";
import { RuneDateField } from "../../fields/RuneDateField";
import { BooleanInverseBadgeField } from "../../fields/BooleanInverseBadgeField";
import { StudyDatagridHeader } from "../../common/StudyDataGrid/StudyDatagridHeader";
import { NoResourceMessage } from "../../common/NoResourceMessage";
import { Patient } from "../../../types";

const NoDeviceMessage = () => (
  <NoResourceMessage>No devices.</NoResourceMessage>
);
const dateFmtOptions = { year: "numeric", month: "short", day: "numeric" };

const groupedLabels = [
  { subLabels: [{ name: "Device ID", sortable: true }] },
  { subLabels: [{ name: "Device Type", sortable: true }] },
  { subLabels: [{ name: "Date Created", sortable: true }] },
  { subLabels: [{ name: "Enabled", sortable: true }] }
];

const DeviceList = () => {
  const patientRecord = useRecordContext<Patient>();
  const { data: user } = useGetIdentity();

  const isSuperAdmin = user?.defaultMembership?.role.superAdmin;
  return (
    <ReferenceManyField
      label="Devices"
      reference={"Device"}
      target={"device_id"}
    >
      <Datagrid
        empty={<NoDeviceMessage />}
        header={<StudyDatagridHeader groupedLabels={groupedLabels} />}
        bulkActionButtons={false}
      >
        <TextField source="deviceShortId" label="Device ID" />
        <TextField source="alias" label="Device Alias" />
        <TextField source="deviceType.displayName" label="Device Type" />
        <RuneDateField
          source="createdAt"
          label="Date Created"
          showTime={true}
          dateFmtOptions={dateFmtOptions}
        />
        <BooleanInverseBadgeField label="Enabled" source="disabled" />
        {isSuperAdmin && <DeviceEditDialog patientId={patientRecord.id} />}
      </Datagrid>
    </ReferenceManyField>
  );
};

export default DeviceList;
