import * as React from "react";
import {
  TabbedShowLayout,
  Tab,
  TextField,
  Labeled,
  useGetIdentity,
  useRecordContext
} from "react-admin";

import { runeTheme } from "../../../common/RuneTheme";
import { RuneDateField } from "../../../fields/RuneDateField";
import DeviceList from "../../device/DeviceList";
import {
  DetailPageGridContainer,
  DetailPageTopRow,
  DetailPageBottomRow,
  DetailPageCol
} from "../../../common/DetailPageGrid";
import PatientUpdate from "../PatientUpdate";
import { useFlags } from "src/clients/launchDarkly";

const PatientDetail = () => {
  const { data: user } = useGetIdentity();
  const isSuperAdmin = user?.defaultMembership?.role.superAdmin;
  const isAdmin = user?.defaultMembership?.role.admin || isSuperAdmin;
  const dateOptions = { year: "numeric", month: "short", day: "numeric" };

  const { patientStartEndDate } = useFlags();

  const record = useRecordContext();

  return (
    <TabbedShowLayout>
      <Tab label="Overview" sx={{ textTransform: "capitalize" }}>
        <DetailPageGridContainer>
          <DetailPageTopRow>
            <DetailPageCol>
              <Labeled label="CODE NAME">
                <TextField
                  source="codeName"
                  sx={{
                    fontSize: "1.4em",
                    color: runeTheme.palette.primary.main
                  }}
                />
              </Labeled>
            </DetailPageCol>

            <DetailPageCol>
              <Labeled
                label={patientStartEndDate ? "START DATE" : "CREATED ON"}
              >
                <RuneDateField
                  source={patientStartEndDate ? "startTime" : "createdAt"}
                  showTime={true}
                  dateFmtOptions={dateOptions}
                />
              </Labeled>
            </DetailPageCol>

            {patientStartEndDate && (
              <DetailPageCol>
                <Labeled label="END DATE">
                  <RuneDateField
                    source="endTime"
                    showTime={true}
                    dateFmtOptions={dateOptions}
                  />
                </Labeled>
              </DetailPageCol>
            )}

            {isAdmin && (
              <DetailPageCol sx={{ width: 200 }}>
                <PatientUpdate patientId={record.id.toString()} />
              </DetailPageCol>
            )}
          </DetailPageTopRow>

          <DetailPageBottomRow>
            <DetailPageCol>
              <Labeled label="PATIENT ID">
                <TextField source="id" />
              </Labeled>
              {isAdmin && (
                <Labeled label="APP MODE">
                  <TextField source="appModeDisplayName" />
                </Labeled>
              )}
              <Labeled label="STRIVE ID">
                <TextField source="striveUserId" />
              </Labeled>
              {isSuperAdmin && (
                <>
                  <Labeled label="FULL NAME">
                    <TextField source="identifiableInfo.realName" />
                  </Labeled>
                  <Labeled label="EMAIL">
                    <TextField source="identifiableInfo.email" />
                  </Labeled>
                </>
              )}
            </DetailPageCol>
          </DetailPageBottomRow>
        </DetailPageGridContainer>
      </Tab>

      <Tab label="Devices" sx={{ textTransform: "capitalize" }}>
        <DeviceList />
      </Tab>
    </TabbedShowLayout>
  );
};

const PatientTabs = () => {
  return <PatientDetail />;
};

export default PatientTabs;
