import { Show, useShowController } from "react-admin";
import { useLocation } from "react-router-dom";

import mixpanel from "mixpanel-browser";

import OrgHeader from "./OrgHeader";
import OrgTabs from "./OrgTabs";

const OrgShow = () => {
  mixpanel.track("OrgShow");
  const location = useLocation();

  const orgId = extractOrgIdFromPath(location.pathname);
  const controllerProps = useShowController({ resource: "Org", id: orgId });
  return (
    <Show
      title="Org Details"
      {...controllerProps}
      emptyWhileLoading
      actions={false}
      component="div"
    >
      <OrgHeader />
      <OrgTabs />
    </Show>
  );
};

// Extract org ID from URL: `/Org/org-f2d9967c4cab422a8ee2769f9de22fbd,org/show`
const extractOrgIdFromPath = (path: string) => {
  const regex = /\/([^/]+,org)\/show$/;
  const match = path.match(regex);
  if (match) {
    return match[1];
  }
  return null;
};

export default OrgShow;
