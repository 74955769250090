import { FunctionField, RaRecord } from "react-admin";

import { runeTheme } from "../../../common/RuneTheme";
import { Badge } from "../../../fields/Badge";
import { Status } from "../../../../constants";

interface MemberStatusProps {
  source: string;
  label: string;
}

export const MemberStatus = (props: MemberStatusProps) => {
  const capitalize = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  return (
    <FunctionField
      {...props}
      render={(record: RaRecord) => {
        const value = record.status.displayName;
        let badgeVariant;

        switch (value) {
          case Status.ACTIVE:
            badgeVariant = runeTheme.palette.badge.success;
            break;
          case Status.INVITED:
            badgeVariant = runeTheme.palette.badge.highlightAlt;
            break;
          case Status.DISABLED:
            badgeVariant = runeTheme.palette.badge.disabled;
            break;
          default:
            badgeVariant = runeTheme.palette.badge.default;
        }

        return (
          <Badge bgColor={badgeVariant} display="inline">
            {capitalize(value.toLowerCase())}
          </Badge>
        );
      }}
    />
  );
};
