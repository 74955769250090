import React, { useState } from "react";

import {
  Link,
  Create,
  SimpleForm,
  TextInput,
  required,
  Toolbar,
  useCreate,
  useRecordContext,
  useNotify,
  Identifier,
  useGetIdentity
} from "react-admin";

import { styled, Box, Dialog, DialogContent, Typography } from "@mui/material";
import mixpanel from "mixpanel-browser";

import { runeTheme } from "../../common/RuneTheme";
import iconAdd from "../../../shared/icon/add.svg";
import iconPerson from "../../../shared/icon/person.svg";
import PrimaryActionDialogButton from "../../common/buttons/PrimaryActionDialogButton";
import SecondaryActionButton from "../../common/buttons/SecondaryActionButton";
import RuneSaveButton from "../../common/buttons/RuneSaveButton";
import RuneCancelButton from "../../common/buttons/RuneCancelButton";

const Wrapper = styled("div")({
  boxShadow: "none"
});

function PatientAddToolbar(props: { handleClickClose: () => void }) {
  return (
    <Toolbar
      className="RuneFormBottomToolbar"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        background: runeTheme.palette.background.light
      }}
      disableGutters={true}
    >
      <SecondaryActionButton
        label={"Cancel"}
        onClick={props.handleClickClose}
      />
      <RuneSaveButton label="ADD" icon={iconAdd} />
    </Toolbar>
  );
}

interface AddPatientSuccessData {
  id: string;
  org: {
    id: string;
  };
  patient: {
    id: string;
    codeName: string;
    deviceList: {
      devices: {
        id: string;
        deviceShortId: string;
      }[];
    };
  };
}

interface AddPatientFormProps {
  handleClickClose: () => void;
  orgId: Identifier;
  orgDisplayName: string;
}

function AddPatientForm(props: AddPatientFormProps) {
  mixpanel.track("PatientAddForm", { props: props });

  const { data: user } = useGetIdentity();
  const isSuperAdmin = user?.defaultMembership?.role.superAdmin;

  const { handleClickClose, orgId, orgDisplayName } = props;
  const [codeName, setCodeName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [patientId, setPatientId] = useState("");

  const [create] = useCreate();
  const notify = useNotify();

  const validateDeviceIdOrPatientId =
    () =>
    (
      value: string,
      allValues: {
        deviceId: string;
        patientId: string;
      }
    ) => {
      if (allValues.deviceId && allValues.patientId) {
        return "Only specify a Device ID or a Patient ID";
      }

      if (!allValues.deviceId && !allValues.patientId) {
        return "Must provide either a Device ID or a Patient ID";
      }
      return undefined;
    };

  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const patientAddToOrg = (data: any) => {
    mixpanel.track("PatientAddToOrg", { props: props });

    const { deviceId, patientId } = data;

    create(
      "PatientAccess",
      { data: { orgId, deviceId, patientId } },
      {
        // https://runelabs.atlassian.net/browse/SW-2470
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        onSuccess: (data: AddPatientSuccessData, variables: any) => {
          const codeName = data.patient.codeName;
          const deviceId = variables.data.deviceId;
          const patientId = variables.data.patientId;

          setCodeName(codeName);
          setDeviceId(deviceId);
          setPatientId(patientId);
        },
        onError(error) {
          if (error instanceof Error) {
            notify(error.message, {
              type: "error"
            });
          } else {
            notify("Unknown error occurred. Unable to add patient to org.", {
              type: "error"
            });
          }
        }
      }
    );
  };

  if ((codeName && deviceId) || (codeName && patientId)) {
    return (
      <AddPatientSuccess
        orgId={orgId}
        orgDisplayName={orgDisplayName}
        codeName={codeName}
        deviceId={deviceId}
        patientId={patientId}
      />
    );
  }

  return (
    <Create
      redirect="show"
      component={Wrapper}
      sx={{
        "& .RaCreate-main": {
          boxShadow: "none",
          borderRadius: "8px"
        }
      }}
    >
      <SimpleForm
        toolbar={PatientAddToolbar({ handleClickClose })}
        onSubmit={patientAddToOrg}
        component={Wrapper}
      >
        <Typography variant="h4">Add Patient to {orgDisplayName}</Typography>

        <Typography sx={{ padding: "20px 0 5px" }}>
          To add a patient, please enter their Device ID{" "}
          {isSuperAdmin ? "or Patient ID" : ""} below. The ID can be found in
          the StrivePD application,
          <Link to="/help" target="_blank" rel="noopener noreferrer">
            {" "}
            see how
          </Link>
          .
        </Typography>

        <TextInput
          source="deviceId"
          label="Device ID"
          variant="outlined"
          fullWidth
          validate={isSuperAdmin ? validateDeviceIdOrPatientId() : required()}
        />
        {isSuperAdmin && (
          <TextInput
            label="Patient ID"
            source="patientId"
            validate={validateDeviceIdOrPatientId()}
            variant="outlined"
            fullWidth
          />
        )}
      </SimpleForm>
    </Create>
  );
}

export function AddPatientDialog() {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <PrimaryActionDialogButton
        label={"Add patient"}
        onClick={handleClickOpen}
        icon={iconPerson}
      />
      <Dialog open={open} onClose={handleClickClose}>
        <DialogContent>
          <AddPatientForm
            handleClickClose={handleClickClose}
            orgId={record.id}
            orgDisplayName={record.displayName}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export function AddPatientSuccess(props: {
  orgId: Identifier;
  orgDisplayName: string;
  codeName: string;
  deviceId?: Identifier;
  patientId?: Identifier;
}) {
  return (
    <Box sx={{ padding: "10px" }}>
      <Typography variant="h4">
        Patient added to {props.orgDisplayName}
      </Typography>
      <Typography sx={{ padding: "20px 0 5px" }}>
        Please note down this information for future reference.
      </Typography>

      {props.deviceId && (
        <>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: "12px",
              marginTop: "20px"
            }}
          >
            Device ID
          </Typography>
          <Typography sx={{ paddingTop: "5px" }}>{props.deviceId}</Typography>
        </>
      )}

      {props.patientId && (
        <>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: "12px",
              marginTop: "20px"
            }}
          >
            Patient ID
          </Typography>
          <Typography sx={{ paddingTop: "5px" }}>{props.patientId}</Typography>
        </>
      )}

      <Typography
        sx={{
          textTransform: "uppercase",
          fontSize: "12px",
          marginTop: "20px"
        }}
      >
        Patient Code Name
      </Typography>

      <Typography sx={{ paddingTop: "5px" }}>{props.codeName}</Typography>

      <Toolbar
        className="RuneFormBottomToolbar"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          background: runeTheme.palette.background.light
        }}
        disableGutters={true}
      >
        <RuneCancelButton buttonType="SHOW" label="CLOSE" resource="Org" />
      </Toolbar>
    </Box>
  );
}
