import React from "react";
import { TextField, Link, useRecordContext } from "react-admin";

import { Breadcrumbs } from "@mui/material";

import { RuneDateField } from "../../../fields/RuneDateField";

import {
  ContentHeaderContainer,
  ContentDetail,
  ContentDetailTopRow,
  ContentDetailTopLine,
  ContentDetailMiddleRow,
  ContentDetailLastRow
} from "../../../common/ContentHeader";

const MemberHeader = () => {
  const record = useRecordContext();
  const orgID = record.org?.id;

  const membersTab = "1";
  const membersTabPath = `/Org/${orgID}/show/${membersTab}`;

  const dateOptions = { year: "numeric", month: "short", day: "numeric" };
  return (
    <ContentHeaderContainer>
      <ContentDetail>
        <ContentDetailTopRow>
          <ContentDetailTopLine>
            User ID: <TextField source="user.id" sx={{ fontSize: "1.2rem" }} />
          </ContentDetailTopLine>
        </ContentDetailTopRow>

        <ContentDetailMiddleRow>
          <Breadcrumbs>
            <TextField
              source="org.displayName"
              variant="h3"
              sx={{ fontWeight: "300" }}
            />

            <Link to={membersTabPath}>Members</Link>

            {record.user.displayName !== null ? (
              <TextField
                source="user.displayName"
                variant="h3"
                sx={{ fontWeight: "400" }}
              />
            ) : (
              <TextField
                source="user.email"
                variant="h3"
                sx={{ fontWeight: "400" }}
              />
            )}
          </Breadcrumbs>
        </ContentDetailMiddleRow>

        <ContentDetailLastRow>
          Created on{" "}
          <RuneDateField
            source="created"
            sx={{ fontWeight: "900" }}
            dateFmtOptions={dateOptions}
          />
        </ContentDetailLastRow>
      </ContentDetail>
    </ContentHeaderContainer>
  );
};

export default MemberHeader;
