import { useRecordContext } from "react-admin";
import { Tooltip } from "@mui/material";

import iconDeviceAppleWatch from "../../../../shared/icon/device-apple-watch.svg";
import iconDevicePhone from "../../../../shared/icon/device-phone.svg";
import iconDeviceMedtronic from "../../../../shared/icon/device-medtronic.svg";
import iconDevicePlaceholder from "../../../../shared/icon/device-placeholder.svg";

import iconDisabledDeviceAppleWatch from "../../../../shared/icon/disabled-device-apple-watch.svg";
import iconDisabledDevicePhone from "../../../../shared/icon/disabled-device-phone.svg";
import iconDisabledDeviceMedtronic from "../../../../shared/icon/disabled-device-medtronic.svg";
import iconDisabledDevicePlaceholder from "../../../../shared/icon/disabled-device-placeholder.svg";

import { DeviceTypeIDs } from "../../../../constants";

interface Device {
  id: string;
  alias: string;
  deviceShortId: string;
  deviceType: {
    displayName: string;
  };
  disabled: boolean;
}

interface PatientDeviceIconsProps {
  label: string;
}

export const PatientDeviceIcons = (props: PatientDeviceIconsProps) => {
  const label = props.label ? props.label : "";
  const patientRecord = useRecordContext();
  const devices = patientRecord?.deviceList?.devices;

  let iconFile, deviceTooltip;

  return (
    <>
      {devices.map((device: Device) => {
        if (device.disabled) {
          deviceTooltip = `[Disabled] ${device.alias}: ${device.deviceShortId}`;
        } else {
          deviceTooltip = `${device.alias}: ${device.deviceShortId}`;
        }

        switch (device.deviceType.displayName) {
          case DeviceTypeIDs.APPLE_WATCH:
            if (device.disabled) {
              iconFile = iconDisabledDeviceAppleWatch;
            } else {
              iconFile = iconDeviceAppleWatch;
            }
            break;
          case DeviceTypeIDs.MEDTRONIC_PERCEPT:
            iconFile = iconDeviceMedtronic;
            if (device.disabled) {
              iconFile = iconDisabledDeviceMedtronic;
            } else {
              iconFile = iconDeviceMedtronic;
            }
            break;
          case DeviceTypeIDs.MEDTRONIC_RCS:
            if (device.disabled) {
              iconFile = iconDisabledDeviceMedtronic;
            } else {
              iconFile = iconDeviceMedtronic;
            }
            break;
          case DeviceTypeIDs.STRIVE_STUDY:
            if (device.disabled) {
              iconFile = iconDisabledDevicePhone;
            } else {
              iconFile = iconDevicePhone;
            }
            break;
          case DeviceTypeIDs.IPHONE:
            if (device.disabled) {
              iconFile = iconDisabledDevicePhone;
            } else {
              iconFile = iconDevicePhone;
            }
            break;
          default:
            if (device.disabled) {
              iconFile = iconDisabledDevicePlaceholder;
            } else {
              iconFile = iconDevicePlaceholder;
            }
        }
        return (
          <Tooltip title={deviceTooltip} key={device.id}>
            <img
              key={device.id}
              src={iconFile}
              alt={label}
              style={{ height: "24px", padding: "3px 5px" }}
            />
          </Tooltip>
        );
      })}
    </>
  );
};
