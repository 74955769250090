import { FunctionField, RaRecord } from "react-admin";
import { runeTheme } from "../common/RuneTheme";
import { Badge } from "./Badge";

// A boolean field for showing the opposite of what the boolean field indicates.
// eg. If the source is `disabled`, but we want the label to be `enabled`.

// Additionally, the result is shown as yes/no instead of the default checkmark/X icons.

interface BooleanInverseBadgeFieldProps {
  source: string;
  label?: string;
}

export const BooleanInverseBadgeField = (
  props: BooleanInverseBadgeFieldProps
) => {
  return (
    <FunctionField
      {...props}
      render={(record: RaRecord) => {
        const value = !record[props.source];

        return value ? (
          <Badge bgColor={runeTheme.palette.badge.success} display="inline">
            Yes
          </Badge>
        ) : (
          <Badge bgColor={runeTheme.palette.badge.fail} display="inline">
            No
          </Badge>
        );
      }}
    />
  );
};
