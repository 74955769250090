import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  required,
  DeleteWithConfirmButton,
  Toolbar,
  useRecordContext,
  minValue,
  maxValue
} from "react-admin";
import { Box, Typography } from "@mui/material";

import { MIN_PROJECT_START_DATE } from "../../../constants";
import { runeTheme } from "../../common/RuneTheme";
import RuneSaveButton from "../../common/buttons/RuneSaveButton";
import RuneCancelButton from "../../common/buttons/RuneCancelButton";
import iconConfirm from "../../../shared/icon/confirm.svg";
import iconCancel from "../../../shared/icon/cancel.svg";

const ProjectEditToolbar = () => {
  const record = useRecordContext();

  return (
    <Toolbar
      className="RuneFormBottomToolbar"
      sx={{
        background: runeTheme.palette.background.light,
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 0 30px"
      }}
    >
      <DeleteWithConfirmButton
        confirmTitle={'Delete project "' + record.title + '"'}
        confirmContent="You will not be able to recover this project. Are you sure?"
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "12px 16px",
          gap: "4px",
          height: "48px",
          borderRadius: "8px",
          borderColor: runeTheme.palette.button.destructive.border,
          fontFamily: "Work Sans",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "14px",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
          "&:hover": {
            borderColor: runeTheme.palette.button.destructive.hoverBorder,
            boxShadow: "none"
          }
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
          backgroundColor: runeTheme.palette.background.light
        }}
      >
        <RuneCancelButton
          buttonType="SHOW"
          icon={iconCancel}
          label={"CANCEL"}
        />
        <RuneSaveButton icon={iconConfirm} label={"SAVE"} />
      </Box>
    </Toolbar>
  );
};

const ProjectEdit = () => {
  return (
    <Edit
      mutationMode="pessimistic"
      redirect="show"
      actions={false}
      sx={{ margin: "5em auto" }}
      queryOptions={{
        meta: {
          metricCategories: ["TREMOR_DYSKINESIA", "MOBILITY", "SLEEP"]
        }
      }}
    >
      <SimpleForm sx={{ maxWidth: 570 }} toolbar={<ProjectEditToolbar />}>
        <Typography variant="h5" gutterBottom>
          Edit project
        </Typography>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={3} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              variant="outlined"
              source="title"
              label="Project Name"
              isRequired
              fullWidth
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              variant="outlined"
              source="type"
              label="Project Type"
              choices={[
                { id: "EXPLORATORY", name: "EXPLORATORY" },
                { id: "CLINICAL_TRIAL", name: "CLINICAL TRIAL" },
                { id: "RETROSPECTIVE_STUDY", name: "RETROSPECTIVE STUDY" },
                { id: "PROSPECTIVE_STUDY", name: "PROSPECTIVE STUDY" },
                { id: "SANDBOX", name: "SANDBOX" }
              ]}
              validate={required()}
            />
          </Box>
        </Box>

        <TextInput
          variant="outlined"
          source="description"
          multiline={true}
          label="Short description"
          fullWidth
        />

        <DateInput
          variant="outlined"
          label="Start Date"
          source="startedAt"
          parse={(val) => new Date(val + "T00:00:00")}
          validate={[
            required(),
            minValue(MIN_PROJECT_START_DATE),
            maxValue(new Date())
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ProjectEdit;
