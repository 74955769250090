import { Show } from "react-admin";

import mixpanel from "mixpanel-browser";

import PatientHeader from "./PatientHeader";
import PatientTabs from "./PatientTabs";

const PatientShow = () => {
  mixpanel.track("PatientShow");

  return (
    <Show
      title="Patient Details"
      emptyWhileLoading
      actions={false}
      component="div"
    >
      <PatientHeader />
      <PatientTabs />
    </Show>
  );
};

export default PatientShow;
