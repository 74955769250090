import { defaultTheme } from "react-admin";
import { COLORS } from "./colors"; // Import the colors

export const runeTheme = {
  palette: {
    background: {
      default: COLORS.COOL_100, // subtle grey
      light: COLORS.WHITE
    },
    primary: {
      main: COLORS.PRIMARY_MAIN // sea blue
    },
    secondary: {
      main: COLORS.SECONDARY_MAIN // dark blue
    },
    disabled: COLORS.PRIMARY_500, // light blue
    divider: COLORS.GREY_400, // light grey
    badge: {
      default: COLORS.PRIMARY_MAIN, // blue
      disabled: COLORS.GREY_600, // grey
      success: COLORS.SUCCESS_MAIN, // green
      fail: COLORS.ERROR_MAIN, // red
      highlight: COLORS.PINK_500, // pink
      highlightAlt: COLORS.SECONDARY_MAIN, // dark blue

      admin: COLORS.BLACK, // black
      clinician: "#3EC3D6", // original hex value

      // org tag colors
      orgTagAggregation: COLORS.BLACK, // black
      orgTagClinical: COLORS.PURPLE_500, // magenta
      orgTagDemo: COLORS.SUCCESS_MAIN, // green
      orgTagInternal: COLORS.PRIMARY_MAIN, // blue
      orgTagResearch: COLORS.PINK_500, // pink
      orgTagResearchRestricted: COLORS.ERROR_MAIN // red
    },
    button: {
      primary: {
        text: COLORS.WHITE,
        background: COLORS.PRIMARY_MAIN,
        hoverText: COLORS.WHITE,
        hoverBackground: COLORS.PRIMARY_1200
      },
      secondary: {
        text: COLORS.PRIMARY_MAIN,
        background: COLORS.WHITE,
        border: COLORS.PRIMARY_MAIN,
        hoverText: COLORS.PRIMARY_MAIN,
        hoverBackground: COLORS.PRIMARY_300,
        hoverBorder: COLORS.PRIMARY_MAIN
      },
      tertiary: {
        text: COLORS.PRIMARY_MAIN
      },
      destructive: {
        text: COLORS.ERROR_MAIN,
        background: "",
        border: COLORS.ERROR_MAIN,
        hoverText: "",
        hoverBackground: "",
        hoverBorder: COLORS.ERROR_DARK
      }
    },
    dateTime: {
      primary: COLORS.BLACK,
      secondary: COLORS.GREY_500
    }
  },
  typography: {
    fontFamily: "Work Sans, sans-serif",
    h3: {
      fontWeight: "400",
      letterSpacing: "-0.03em",
      display: "inline",
      color: COLORS.BLACK
    },
    h4: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "28px",
      color: COLORS.BLACK
    },
    body1: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "160%",
      color: COLORS.BLACK
    },
    subtitle1: {
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "normal",
      letterSpacing: "0.6px",
      color: COLORS.BLACK
    }
  },
  components: {
    ...defaultTheme.components,
    RaLabeled: {
      styleOverrides: {
        root: {
          marginBottom: "20px"
        }
      }
    },
    RaShow: {
      styleOverrides: {
        root: {
          padding: "0px 30px !important",
          overflow: "auto",
          "& .RaShow-card": {
            maxWidth: "100vw",
            overflow: "auto"
          }
        }
      }
    },

    RaDatagrid: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.WHITE,
          "& .RaDatagrid-rowEven": {
            backgroundColor: COLORS.WARM_100
          },
          "& .RaDatagrid-row": {
            // With the amount of projects, patients and orgs growing,
            // let's try a more compact row height
            // Keeping the value here so we can restore/adjust as needed
            // height: "66px"
          },
          "& .RaDatagrid-row:hover": {
            backgroundColor: COLORS.PRIMARY_400
          },
          "& .RaDatagrid-headerRow": {
            height: "36px"
          },
          "& .RaDatagrid-tableWrapper": {
            maxWidth: "100vw",
            overflow: "auto"
          },
          "& .RaDatagrid-rowOdd + .RaDatagrid-expandedPanel": {
            backgroundColor: COLORS.WHITE // Background color for odd rows
          },
          "& .RaDatagrid-rowEven + .RaDatagrid-expandedPanel": {
            backgroundColor: COLORS.WARM_100 // Background color for even rows
          }
        }
      }
    },
    RaEdit: {
      styleOverrides: {
        root: {
          width: "30em",
          "& .RaCreate-main": {
            boxShadow:
              "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
            borderRadius: "16px"
          }
        }
      }
    },
    RaCreate: {
      styleOverrides: {
        root: {
          width: "30em",
          "& .RaCreate-main": {
            boxShadow:
              "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)",
            borderRadius: "16px"
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: COLORS.WHITE,
          boxShadow:
            "0px 5px 22px rgba(0, 0, 0, 0.12), 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 7px 8px rgba(0, 0, 0, 0.2)",
          fontFamily: "'Work Sans'",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "146%",
          letterSpacing: "-0.03em",
          color: COLORS.BLACK
        },
        arrow: {
          color: COLORS.WHITE
        }
      }
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          alignItems: "center"
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: "0px 24px",
          marginBottom: "-12px"
        },
        indicator: {
          backgroundColor: COLORS.PRIMARY_300,
          height: "9px",
          borderRadius: "6px 6px 0px 0px"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "28px",
          letterSpacing: "-0.03em",
          paddingBottom: "20px"
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          display: "none"
        }
      }
    },
    RaTabbedShowLayout: {
      styleOverrides: {
        root: {
          "& .RaTabbedShowLayout-content": {
            paddingLeft: "0px",
            paddingRight: "0px"
          }
        }
      }
    },
    RaBulkActionsToolbar: {
      color: COLORS.BLACK,
      styleOverrides: {
        root: {
          color: COLORS.BLACK
        },
        toolbar: {
          color: COLORS.BLACK
        },
        title: {
          color: COLORS.BLACK
        }
      }
    },
    MuiToolbar: {
      color: COLORS.BLACK,
      styleOverrides: {
        root: {
          color: COLORS.BLACK
        },
        regular: {
          color: COLORS.BLACK
        }
      }
    },
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent"
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: "49px",
          fontWeight: "300",
          letterSpacing: "-0.03em"
        },
        li: {
          color: COLORS.PRIMARY_MAIN
        },
        separator: {
          color: COLORS.GREY_400,
          padding: "0 5px",
          fontWeight: "100",
          fontSize: "49px"
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          ":hover": {
            textDecoration: "none",
            color: COLORS.BLACK
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "36px"
        }
      }
    }
  }
};
