import { styled } from "@mui/material";

import { runeTheme } from "../common/RuneTheme";

const DetailPageGridContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "space-between",
  padding: "30px 30px 20px 30px",
  background: runeTheme.palette.background.light
});

const DetailPageTopRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  borderBottom: "1px solid",
  borderColor:
    runeTheme.components.MuiBreadcrumbs.styleOverrides.separator.color
});

const DetailPageBottomRow = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  marginTop: "30px"
});

const DetailPageCol = styled("div")({
  display: "flex",
  flexDirection: "column"
});

export {
  DetailPageGridContainer,
  DetailPageTopRow,
  DetailPageBottomRow,
  DetailPageCol
};
