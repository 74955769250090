import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildQueryUserAccessTokenParams {
  data: {
    accessTokenId: string;
    userId: string;
    disabled: boolean;
  };
  meta: {
    nextPageCursor: string;
  };
}

interface UserAccessToken {
  id: string;
  accessTokenId: string;
  createdAt: number;
  disabled: boolean;
  disabledAt: number;
  updatedAt: number;
  usedAt: number;
}

function formatDyanmoAccessToken(userId: string, tokenId: string) {
  if (tokenId.startsWith("user")) {
    return tokenId;
  }
  const prefix = userId.split(",")[0];
  const suffix = `accesstoken-${tokenId}`;
  return `${prefix},${suffix}`;
}

const buildQueryUserAccessToken = (
  fetchType: string,
  params: BuildQueryUserAccessTokenParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.GET_LIST:
      return {
        query: gql`
          query getUserAccessTokens($cursor: Cursor) {
            user {
              id
              accessTokenList(cursor: $cursor) {
                accessTokens {
                  id
                  accessTokenId
                  createdAt
                  disabled
                  disabledAt
                  updatedAt
                  usedAt
                }
              }
            }
          }
        `,
        variables: { cursor: params.meta.nextPageCursor },
        parseResponse: (response) => {
          const data = response.data.user.accessTokenList.accessTokens.map(
            (accessToken: UserAccessToken) => {
              return {
                ...accessToken,
                disabledAt: accessToken.disabledAt * 1000,
                createdAt: accessToken.createdAt * 1000,
                updatedAt: accessToken.updatedAt * 1000,
                usedAt: accessToken.usedAt ? accessToken.usedAt * 1000 : null
              };
            }
          );
          return {
            data: data,
            total: data.length
          };
        }
      };
    case QueryTypes.CREATE:
      return {
        query: gql`
          mutation createAccessToken {
            createAccessToken {
              accessToken {
                id
                accessTokenId
                createdAt
                disabled
                disabledAt
                updatedAt
                usedAt
              }
              accessTokenSecret
            }
          }
        `,
        variables: {},
        parseResponse: (response) => {
          return {
            data: {
              id: response.data.createAccessToken.accessToken.id,
              tokenId:
                response.data.createAccessToken.accessToken.accessTokenId,
              tokenSecret: response.data.createAccessToken.accessTokenSecret
            }
          };
        }
      };
    case QueryTypes.UPDATE:
      return {
        query: gql`
          mutation updateAccessToken($input: UpdateAccessTokenInput!) {
            updateAccessToken(input: $input) {
              accessToken {
                id
                accessTokenId
                createdAt
                disabled
                disabledAt
                updatedAt
                usedAt
              }
            }
          }
        `,
        variables: {
          input: {
            accessTokenId: formatDyanmoAccessToken(
              params.data.userId,
              params.data.accessTokenId
            ),
            disabled: params.data.disabled
          }
        },
        parseResponse: (response) => {
          const data = response.data.updateAccessToken.accessToken;

          return {
            data: data
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryUserAccessToken;
