import { ReactNode } from "react";
import { useGetIdentity } from "react-admin";
import mixpanel from "mixpanel-browser";

interface MixPanelContextProps {
  children: ReactNode;
}

export const MixPanelContext = (props: MixPanelContextProps) => {
  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const config = (window as any).Rune.Carrot.config;
  const mixpanelConfig = config.mixpanel;

  const { data: user } = useGetIdentity();

  if (user?.id) {
    mixpanel.identify(user.username);
    mixpanel.alias(user.id.toString(), user.username);
    mixpanel.alias(user.email, user.username);
    mixpanel.add_group("role", user.defaultMembership.role.displayName);
    mixpanel.add_group("org", user.defaultMembership.org.displayName);
    mixpanel.add_group("env", mixpanelConfig.environment);
  }

  return <>{props.children}</>;
};
