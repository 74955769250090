import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  styled
} from "@mui/material";
import {
  BooleanInput,
  Button,
  Labeled,
  SimpleForm,
  Toolbar,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
  useGetIdentity
} from "react-admin";
import mixpanel from "mixpanel-browser";
import { useState } from "react";
import { runeTheme } from "src/components/common/RuneTheme";
import SecondaryActionButton from "src/components/common/buttons/SecondaryActionButton";
import RuneSaveButton from "src/components/common/buttons/RuneSaveButton";
import iconCheckMark from "../../../shared/icon/checkmark.svg";

export function UserAccessTokenEditDialog() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button label="edit" onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClickClose}
        fullWidth
        sx={{ width: "500px", margin: "auto" }}
      >
        <DialogTitle>Edit Access Token</DialogTitle>
        <DialogContent>
          <UserAccessTokenEditForm handleClickClose={handleClickClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}

function UserAccessTokenEditToolbar(props: { handleClickClose: () => void }) {
  return (
    <Toolbar
      className="RuneFormBottomToolbar"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        margin: 0,
        background: runeTheme.palette.background.light
      }}
      disableGutters={true}
    >
      <SecondaryActionButton
        label={"Cancel"}
        onClick={props.handleClickClose}
      />
      <RuneSaveButton icon={iconCheckMark} label={"SAVE"} />
    </Toolbar>
  );
}

function UserAccessTokenEditForm(props: { handleClickClose: () => void }) {
  mixpanel.track("UserAccessTokenEditForm", { props: props });

  const [update] = useUpdate();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const { handleClickClose } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userAccessToken = useRecordContext<any>();
  const { data: user } = useGetIdentity();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const UserAccessTokenEditSave = (data: any) => {
    mixpanel.track("DeviceEditSave", { props: props });

    const userAccessTokenId = userAccessToken.id.startsWith("user")
      ? userAccessToken.id
      : userAccessToken.accessTokenId;
    const { disabled } = data;

    update(
      "UserAccessToken",
      {
        data: {
          accessTokenId: userAccessTokenId,
          disabled: disabled,
          userId: user?.id
        }
      },
      {
        onSuccess() {
          notify(`User Access Token updated successfully.`);
          redirect("/UserAccessToken");
          refresh();
        },
        onError(error) {
          if (error instanceof Error) {
            notify(error.message, {
              type: "error"
            });
          } else {
            notify("Unknown error occurred. Unable to update the user token.", {
              type: "error"
            });
          }
        }
      }
    );
  };

  const Wrapper = styled("div")({
    boxShadow: "none"
  });

  return (
    <SimpleForm
      toolbar={UserAccessTokenEditToolbar({ handleClickClose })}
      onSubmit={UserAccessTokenEditSave}
      record={userAccessToken}
      component={Wrapper}
      sx={{ display: "block" }}
    >
      <Labeled label="User Access Token ID">
        <Typography>{userAccessToken.accessTokenId}</Typography>
      </Labeled>

      <Box display="flex" alignItems="top" width={"100%"}>
        <Box flexGrow={1}>
          <Labeled label="STATUS">
            <Typography>Enabled</Typography>
          </Labeled>
        </Box>
        <Box>
          <BooleanInput
            source="disabled"
            label="Yes"
            format={(v) => !v}
            parse={(v) => !v}
            sx={{ paddingTop: "15px" }}
          />
        </Box>
      </Box>
    </SimpleForm>
  );
}
