import { Typography } from "@mui/material";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useDataProvider, useRecordContext } from "react-admin";

// label and source, while not used in this component, are used by the StudyDatagridHeader to determine column display
// We disable the lint warning accordingly
/* eslint-disable @typescript-eslint/no-unused-vars */
export const DiagnosticField = (props: { label: string; source: string }) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const patientId = get(record, "id");

  type DiagnosticInfo = {
    date: Date | null;
    iosVersion: string;
    watchosVersion: string;
    appVersion: string;
    mm4pdPermission: string;
    appBuild: string;
    pharmaMode: string;
  };

  const [diagnosticInfo, setDiagnosticInfo] = useState<DiagnosticInfo>({
    date: null,
    iosVersion: "",
    watchosVersion: "",
    appVersion: "",
    mm4pdPermission: "",
    appBuild: "",
    pharmaMode: ""
  });

  const params = {
    meta: {
      filters: {
        patientId: patientId,
        algorithm: "ingest-rune-events.0",
        parameters: [
          {
            key: "category",
            value: "device_info"
          }
        ]
      }
    }
  };

  useEffect(() => {
    dataProvider
      .getStreamIds(params)
      /* eslint-disable @typescript-eslint/no-explicit-any */
      .then((result: any) => {
        if (!result.data || result.data.length === 0) {
          throw new Error("No stream data available");
        }

        interface StreamMetaData {
          id: string;
          maxTime: number;
          minTime: number;
        }

        const mostRecentStreamMetaData = result.data.reduce(
          (a: StreamMetaData, b: StreamMetaData) =>
            a.maxTime > b.maxTime ? a : b
        );

        const startDateTimestamp = mostRecentStreamMetaData.maxTime - 10;
        const endDateTimestamp = mostRecentStreamMetaData.maxTime + 10;

        const params = {
          start_time: JSON.stringify(startDateTimestamp),
          end_time: JSON.stringify(endDateTimestamp),
          format: "json"
        };

        return dataProvider.getStream(mostRecentStreamMetaData.id, params);
      })
      /* eslint-disable @typescript-eslint/no-explicit-any */
      .then((streamData: any) => {
        if (!streamData || !streamData.json || !streamData.json.data) {
          throw new Error("No stream data available");
        }
        const lastDiagnosticRecord =
          streamData.json.data.event[streamData.json.cardinality - 1];
        const eventTimestamp =
          streamData.json.data.time[streamData.json.cardinality - 1];
        const eventDate = new Date(eventTimestamp * 1000);

        setDiagnosticInfo({
          date: eventDate,
          iosVersion: lastDiagnosticRecord.os_version,
          watchosVersion: "Not Set Yet",
          appVersion: lastDiagnosticRecord.app_version,
          mm4pdPermission:
            lastDiagnosticRecord.core_motion_authorization_status,
          appBuild: lastDiagnosticRecord.app_build,
          pharmaMode: lastDiagnosticRecord.pharma_mode || "unknown"
        });
      })
      /* eslint-disable @typescript-eslint/no-explicit-any */
      .catch((error: any) => {
        console.error("Error fetching diagnostic data:");
      });
  }, [dataProvider]);

  if (diagnosticInfo.date === null) {
    return (
      <Typography
        variant="body2"
        component="span"
        style={{ display: "flex", alignItems: "center" }}
      >
        No diagnostic data available
      </Typography>
    );
  }
  return (
    <Typography
      variant="body2"
      component="span"
      style={{ display: "flex", alignItems: "center" }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ padding: "0 10px 0 0", flex: "0 1 auto" }}>
          iOS: <b>{diagnosticInfo.iosVersion}</b>
        </div>
        <div style={{ padding: "0 10px 0 0", flex: "0 1 auto" }}>
          / App: <b>{diagnosticInfo.appVersion}</b>
        </div>
        <div style={{ padding: "0 10px 0 0", flex: "0 1 auto" }}>
          / Mode: <b>{diagnosticInfo.pharmaMode}</b>
        </div>
        <div style={{ padding: "0 10px 0 0", flex: "0 1 auto" }}>
          <i>
            (
            {diagnosticInfo.date
              ? diagnosticInfo.date.toLocaleDateString()
              : ""}
            )
          </i>
        </div>
        {diagnosticInfo.mm4pdPermission !== "authorized" && (
          <div style={{ padding: "0 10px 0 0", flex: "0 1 auto" }}>
            / MM4PD Permission: <b>{diagnosticInfo.mm4pdPermission}</b>
          </div>
        )}
      </div>
    </Typography>
  );
};
