import {
  DataProvider,
  GetListResult,
  GetListParams,
  GetOneParams,
  GetManyParams,
  GetManyReferenceParams,
  CreateParams,
  UpdateParams,
  UpdateManyParams,
  DeleteParams,
  DeleteManyParams
} from "react-admin";
import { BuildQueryResult } from "ra-data-graphql";
import { stringify } from "query-string";

import buildQueryAppModes from "./buildQueryAppModes";

import buildQueryProject from "./buildQueryProject";
import buildQueryProjectPatient from "./buildQueryProjectPatient";
import buildQueryCohortPatient from "./buildQueryCohortPatient";
import buildQueryCohort from "./buildQueryCohort";
import buildQueryOrg from "./buildQueryOrg";
import buildQueryOrgTags from "./buildQueryOrgTags";
import buildQueryReferralCode from "./buildQueryReferralCode";

import buildQueryPatient from "./buildQueryPatient";
import buildQueryPatientAccess from "./buildQueryPatientAccess";
import buildQueryUserOrg from "./buildQueryUserOrg";
import buildQueryMember from "./buildQueryMember";

import buildQueryDevice from "./buildQueryDevice";
import buildQueryDeviceTypes from "./buildQueryDeviceTypes";
import { clientSideSorting } from "./clientSideSorting";
import buildQueryPatientStream from "./buildQueryPatientStream";
import buildQueryStream from "./buildQueryStream";
import { STREAM_V2_PREFIX } from "src/constants";
import buildQueryPatientEvent from "./buildQueryPatientEvent";
import buildQueryDataExport from "./buildQueryDataExport";
import buildQueryUserAccessToken from "./buildUserAccessToken";

// https://runelabs.atlassian.net/browse/SW-2470
/* eslint-disable @typescript-eslint/no-explicit-any */
export const buildQuery =
  () =>
  (fetchType: string, resourceName: string, params: any): BuildQueryResult => {
    switch (resourceName) {
      case "AppModes":
        return buildQueryAppModes(fetchType);
      case "CohortPatient":
        return buildQueryCohortPatient(fetchType, params);
      case "Cohort":
        return buildQueryCohort(fetchType, params);
      case "ProjectPatient":
        return buildQueryProjectPatient(fetchType, params);
      case "Project":
        return buildQueryProject(fetchType, params);
      case "ReferralCode":
        return buildQueryReferralCode(fetchType, params);
      case "Org":
        return buildQueryOrg(fetchType, params);
      case "OrgTags":
        return buildQueryOrgTags(fetchType, params);
      case "UserOrg":
        return buildQueryUserOrg(fetchType, params);
      case "Member":
        return buildQueryMember(fetchType, params);
      case "Patient":
        return buildQueryPatient(fetchType, params);
      case "PatientAccess":
        return buildQueryPatientAccess(fetchType, params);
      case "PatientStream":
        return buildQueryPatientStream(fetchType, params);
      case "Device":
        return buildQueryDevice(fetchType, params);
      case "DeviceTypes":
        return buildQueryDeviceTypes(fetchType);
      case "Stream":
        return buildQueryStream(fetchType, params);
      case "PatientEvent":
        return buildQueryPatientEvent(fetchType, params);
      case "DataExport":
        return buildQueryDataExport(fetchType, params);
      case "UserAccessToken":
        return buildQueryUserAccessToken(fetchType, params);
    }
    throw Error(`unknown resource ${resourceName} or fetch type ${fetchType}`);
  };

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const wrapDataProvider = (
  wrapped: DataProvider,
  httpClient: any
): DataProvider => {
  return {
    getList: (resource: string, params: GetListParams) => {
      return exhaustPages(wrapped.getList, resource, params);
    },
    getOne: (resource: string, params: GetOneParams) =>
      wrapped.getOne(resource, params),
    getMany: (resource: string, params: GetManyParams) =>
      wrapped.getMany(resource, params),
    getManyReference: (resource: string, params: GetManyReferenceParams) => {
      return exhaustPages(wrapped.getManyReference, resource, params);
    },
    create: (resource: string, params: CreateParams) =>
      wrapped.create(resource, params),
    update: (resource: string, params: UpdateParams) =>
      wrapped.update(resource, params),
    updateMany: (resource: string, params: UpdateManyParams) =>
      wrapped.updateMany(resource, params),
    delete: (resource: string, params: DeleteParams) =>
      wrapped.delete(resource, params),
    deleteMany: (resource: string, params: DeleteManyParams) =>
      wrapped.deleteMany(resource, params),
    getStreamIds: (params: any) => {
      return wrapped.getList("Stream", params);
    },
    getStreamAggregateWindow: (streamId: string, params: any) => {
      return httpClient(
        STREAM_V2_PREFIX + streamId + "/aggregate_window?" + stringify(params)
      );
    },
    getStreamDailyAggregate: (streamId: string, params: any) => {
      return httpClient(
        STREAM_V2_PREFIX + streamId + "/daily_aggregate?" + stringify(params)
      );
    },
    getStream: (streamId: string, params: any) => {
      return httpClient(STREAM_V2_PREFIX + streamId + "?" + stringify(params));
    },
    getStreamAvailability: (streamId: string, params: any) => {
      return httpClient(
        STREAM_V2_PREFIX + streamId + "/availability?" + stringify(params)
      );
    },
    getStreamRecentAvailability: (metricCategories: string[], params: any) => {
      return httpClient(
        "/v2/metric_categories/recent_availability?metric_categories=" +
          metricCategories.join(",") +
          "&" +
          stringify(params)
      );
    }
  };
};

async function exhaustPages(
  dataProviderFunc: any,
  resource: string,
  params: any
): Promise<GetListResult> {
  const allRecords: any[] = [];
  let nextPageCursor: string | null = null;
  let fetchError: any;
  while (true) {
    try {
      if (params.meta === undefined) {
        params.meta = {};
      }
      params.meta.nextPageCursor = nextPageCursor;

      const result = await dataProviderFunc(resource, params);

      allRecords.push(...result.data);

      // We've gone through all the pages, so we're done.
      if (!result.endCursor) {
        break;
      }

      nextPageCursor = result.endCursor;
    } catch (e) {
      // If we get an error at all, set it and we'll reject the promise with it below.
      fetchError = e;
      break;
    }
  }

  return new Promise((resolve, reject) => {
    if (fetchError) {
      reject(fetchError);
    }
    resolve({
      // Handle in-memory sorting on full result set (since Carrot Graph does not support server-side sorting for Dynamo queries)
      data:
        params.sort != null
          ? clientSideSorting(allRecords, params)
          : allRecords,
      total: allRecords.length
    });
  });
}
