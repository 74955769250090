import { get } from "lodash";
import { useRecordContext } from "react-admin";

interface PatientIdLinkFieldProps {
  tooltip?: string;
  icon: string;
}

export const PatientIdLinkField = (props: PatientIdLinkFieldProps) => {
  const record = useRecordContext();
  const patientId = get(record, "id");

  return (
    <span
      style={{ cursor: "pointer", marginLeft: "10px" }}
      onClick={() => {
        // TODO: SW-3467: This is a temporary solution to get the portal URL
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const config: { carrotGraph: any } = (window as any).Rune.Carrot.config;
        const portalUrl: string = config.carrotGraph.host.replace(
          "graph",
          "app"
        );
        window.open(
          "https://" + portalUrl + "/patients/" + patientId,
          "_blank"
        );
      }}
    >
      <img
        src={props.icon}
        alt="Open in StrivePD portal"
        style={{ height: "16px" }}
        title={props.tooltip}
      />
    </span>
  );
};
