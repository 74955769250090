import React, { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  Toolbar,
  minLength,
  maxLength,
  required,
  useDataProvider,
  useRefresh,
  useCreate,
  useNotify,
  useRecordContext
} from "react-admin";

import { styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { runeTheme } from "../../common/RuneTheme";
import { Project } from "../../../types";
import RuneSaveButton from "../../common/buttons/RuneSaveButton";
import SecondaryActionButton from "../../common/buttons/SecondaryActionButton";
import iconAdd from "../../../shared/icon/add.svg";

const Wrapper = styled("div")({
  boxShadow: "none"
});

interface AppMode {
  id: number;
  name: string;
}

function CreateReferralCodeToolbar(props: { handleClickClose: () => void }) {
  return (
    <Toolbar
      sx={{
        background: runeTheme.palette.background.light,
        display: "flex",
        justifyContent: "flex-end"
      }}
      disableGutters={true}
    >
      <SecondaryActionButton
        onClick={props.handleClickClose}
        label={"Cancel"}
      />
      <RuneSaveButton label="Referral Code" icon={iconAdd} />
    </Toolbar>
  );
}

function CreateReferralCodeForm(props: { handleClickClose: () => void }) {
  const { handleClickClose } = props;
  const [create] = useCreate();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const [appModes, setAppModes] = useState<AppMode[]>([]);

  const validateReferralCode = [required(), minLength(4), maxLength(35)];

  const project = useRecordContext<Project>();

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const save = (data: any) => {
    // Set the organizationId and projectId which is required
    // for the mutation
    data.organizationId = project.organizationId;
    data.projectId = project.id;

    create(
      "ReferralCode",
      { data },
      {
        onSuccess(data) {
          if (data.errors && data.errors.length > 0) {
            notify(`Error: ${data.errors[0].message}`, {
              type: "error"
            });
            return;
          }
          refresh();
          notify(`Referral code ${data.id} created.`, {
            type: "info"
          });
        }
      }
    );
  };

  useEffect(() => {
    dataProvider
      .getMany("AppModes", { ids: [] })
      .then((response) => {
        const appModesArray = response.data.map((appMode) => {
          return {
            id: appMode.id,
            name: appMode.displayName
          };
        });
        setAppModes(appModesArray);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dataProvider]);

  if (appModes.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Create
      component={Wrapper}
      sx={{
        "& .RaCreate-main": {
          boxShadow: "none",
          borderRadius: "8px"
        }
      }}
      redirect={false}
    >
      <SimpleForm
        sx={{ maxWidth: 570 }}
        toolbar={CreateReferralCodeToolbar({ handleClickClose })}
        onSubmit={save}
      >
        <TextInput
          variant="outlined"
          source="code"
          id="code"
          label="Referral Code"
          fullWidth
          validate={validateReferralCode}
          onChange={(e) => {
            e.target.value = e.target.value.toUpperCase();
          }}
        />
        <SelectInput
          variant="outlined"
          source="appModeId"
          label="App Mode"
          fullWidth
          choices={appModes}
          isRequired
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
}

export default function CreateReferralCodeDialog() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SecondaryActionButton
        label={"Create Referral Code"}
        onClick={handleClickOpen}
        icon={<AddIcon />}
      />
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Create a referral code</DialogTitle>
        <DialogContent>
          <CreateReferralCodeForm handleClickClose={handleClickClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}
