import { SaveButton } from "react-admin";
import { runeTheme } from "../../common/RuneTheme";

export interface RuneSaveButtonProps {
  icon: string;
  label: string;
}

const RuneSaveButton = (props: RuneSaveButtonProps) => {
  return (
    <SaveButton
      label={props.label}
      variant="contained"
      icon={
        <img
          src={props.icon}
          alt=""
          style={{ height: "10px", padding: "0 5px 0 0" }}
        />
      }
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "12px 16px",
        gap: "4px",
        height: "48px",
        background: runeTheme.palette.primary.main,
        borderRadius: "8px",

        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        color: runeTheme.palette.background.light,
        boxShadow: "none",

        "&:hover": {
          boxShadow: "none",
          background: "#005694"
        }
      }}
    />
  );
};

export default RuneSaveButton;
