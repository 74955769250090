import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { createHttpLink } from "@apollo/client/link/http";

const getBaseUrl = function (host: string, port = 443, secure = true) {
  const protocol = secure ? "https" : "http";
  let url = `${protocol}://${host}`;

  if ((secure && port !== 443) || (!secure && port !== 80)) {
    url = `${url}:${port}`;
  }

  return `${url}/graphql`;
};

/**
 * Create an ApolloClient for Carrot GraphQL calls
 * @param host
 * @param port
 * @param secure
 * @param authLink
 * @return {ApolloClient<any>}
 */
export const createGraphqlClient = function (
  host: string,
  port = 443,
  secure = true,
  authLink: ApolloLink
) {
  const uri = getBaseUrl(host, port, secure);
  const link = createHttpLink({ uri });

  return new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        errorPolicy: "all"
      },
      mutate: {
        errorPolicy: "all"
      }
    }
  });
};
