import React, { useState, useEffect } from "react";

import {
  Create,
  SimpleForm,
  TextInput,
  CheckboxGroupInput,
  required,
  Toolbar,
  useCreate,
  useRefresh,
  useNotify,
  useDataProvider
} from "react-admin";

import { styled, Dialog, DialogContent, Typography } from "@mui/material";
import mixpanel from "mixpanel-browser";

import { runeTheme } from "../../common/RuneTheme";
import iconAdd from "../../../shared/icon/add.svg";
import PrimaryActionDialogButton from "../../common/buttons/PrimaryActionDialogButton";
import SecondaryActionButton from "../../common/buttons/SecondaryActionButton";
import RuneSaveButton from "../../common/buttons/RuneSaveButton";

interface OrgTag {
  id: number;
  name: string;
}

const Wrapper = styled("div")({
  boxShadow: "none"
});

function CreateOrgToolbar(props: { handleClickClose: () => void }) {
  return (
    <Toolbar
      className="RuneFormBottomToolbar"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        background: runeTheme.palette.background.light
      }}
      disableGutters={true}
    >
      <SecondaryActionButton
        label={"Cancel"}
        onClick={props.handleClickClose}
      />
      <RuneSaveButton label="Create" icon={iconAdd} />
    </Toolbar>
  );
}

interface CreateOrgFormProps {
  handleClickClose: () => void;
}

function CreateOrgForm(props: CreateOrgFormProps) {
  mixpanel.track("CreateOrgForm", { props: props });

  const { handleClickClose } = props;

  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const createOrg = (data: any) => {
    mixpanel.track("CreateOrg", { props: props });
    create(
      "Org",
      { data: data },
      {
        // https://runelabs.atlassian.net/browse/SW-2470
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        onSuccess: (variables: any) => {
          const displayName = variables.displayName;
          notify(`Successfully created org: ${displayName}`);
          refresh();
        },
        onError(error) {
          if (error instanceof Error) {
            notify(error.message, {
              type: "error"
            });
          } else {
            notify("Unknown error occurred. Unable to create org.", {
              type: "error"
            });
          }
        }
      }
    );
  };

  const [orgTags, setOrgTags] = useState<OrgTag[]>([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .getMany("OrgTags", { ids: [] })
      .then((response) => {
        setOrgTags(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dataProvider]);

  return (
    <Create
      redirect="show"
      component={Wrapper}
      sx={{
        "& .RaCreate-main": {
          boxShadow: "none",
          borderRadius: "8px"
        }
      }}
    >
      <SimpleForm
        toolbar={CreateOrgToolbar({ handleClickClose })}
        onSubmit={createOrg}
        component={Wrapper}
      >
        <Typography variant="h4" sx={{ padding: "0 0 20px 0" }}>
          Create Organization
        </Typography>

        <TextInput
          source="displayName"
          label="Name"
          variant="outlined"
          fullWidth
          validate={required()}
        />
        {orgTags.length === 0 ? (
          <div>Loading...</div>
        ) : (
          <CheckboxGroupInput
            source="orgTags"
            choices={orgTags}
            row={false}
            label="Tags"
          />
        )}
      </SimpleForm>
    </Create>
  );
}

export function CreateOrgDialog() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <PrimaryActionDialogButton
        label={"Create Organization"}
        onClick={handleClickOpen}
        icon={iconAdd}
      />
      <Dialog open={open} onClose={handleClickClose}>
        <DialogContent>
          <CreateOrgForm handleClickClose={handleClickClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}
