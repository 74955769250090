import { FunctionField, RaRecord } from "react-admin";

import { runeTheme } from "../../../common/RuneTheme";
import { Badge } from "../../../fields/Badge";
import { Role } from "../../../../constants";

interface MemberRoleProps {
  source: string;
  label: string;
}

export const MemberRole = (props: MemberRoleProps) => {
  return (
    <FunctionField
      {...props}
      render={(record: RaRecord) => {
        const value = record.role.displayName;
        let badgeVariant;

        switch (value) {
          case Role.MEMBER:
            badgeVariant = runeTheme.palette.badge.default;
            break;
          case Role.STUDY_MEMBER:
            badgeVariant = runeTheme.palette.badge.default;
            break;
          case Role.STUDY_SERVICE_ACCOUNT:
            badgeVariant = runeTheme.palette.badge.default;
            break;
          case Role.ADMIN:
            badgeVariant = runeTheme.palette.badge.admin;
            break;
          case Role.CLINICIAN:
            badgeVariant = runeTheme.palette.badge.clinician;
            break;
          default:
            badgeVariant = runeTheme.palette.badge.default;
        }

        return (
          <Badge bgColor={badgeVariant} display="inline-block">
            {value}
          </Badge>
        );
      }}
    />
  );
};
