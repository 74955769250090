import { Box } from "@mui/material";
import mixpanel from "mixpanel-browser";

export const ContactPage = () => {
  mixpanel.track("ContactPage");
  return (
    <Box sx={{ padding: "8px 56px" }}>
      <h1>Contact us</h1>
      <h2>EMAIL</h2>
      <h3>General Inquiry</h3>
      <p>
        <a href="mailto:support@runelabs.io">support@runelabs.io</a>
      </p>
      <h3>Security & Privacy</h3>
      <p>
        <a href="mailto:security@runelabs.io">security@runelabs.io</a>
      </p>
      <h2>LOCATION</h2>
      <p>
        1314 8th Ave
        <br />
        San Francisco, CA 94122
      </p>
    </Box>
  );
};
