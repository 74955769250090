import get from "lodash/get";

// https://runelabs.atlassian.net/browse/SW-2470
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const clientSideSorting = (allRecords: any, params: any): any =>
  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  allRecords.sort((a: any, b: any) => {
    let aVal = get(a, params.sort.field, null);
    let bVal = get(b, params.sort.field, null);

    if (aVal == null) {
      aVal = "";
    }
    if (bVal == null) {
      bVal = "";
    }

    // Normalize if strings.
    if (typeof aVal === "string") {
      aVal = aVal.toLocaleLowerCase();
    }
    if (typeof bVal === "string") {
      bVal = bVal.toLocaleLowerCase();
    }

    let sortResult = 0;
    if (aVal > bVal) {
      sortResult = -1;
    } else if (aVal < bVal) {
      sortResult = 1;
    }

    // Reverse comparison if sorting in ascending order.
    if (params.sort.order === "ASC") {
      return sortResult * -1;
    }
    return sortResult;
  });
