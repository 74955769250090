import React, { useState } from "react";
import { useRecordContext } from "react-admin";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import iconPerson from "../../../shared/icon/person.svg";

import PrimaryActionDialogButton from "../../common/buttons/PrimaryActionDialogButton";
import MemberInviteForm from "./MemberInviteForm";

export function InviteMemberDialog() {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <PrimaryActionDialogButton
        label={"Invite Members"}
        onClick={handleClickOpen}
        icon={iconPerson}
      />
      <Dialog
        open={open}
        onClose={handleClickClose}
        fullWidth
        maxWidth="md"
        sx={{ width: "800px", minHeight: "400px", margin: "auto" }}
      >
        <DialogTitle>Invite Members</DialogTitle>
        <DialogContent>
          <MemberInviteForm
            handleClickClose={handleClickClose}
            record={record}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
