import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildQueryUserOrgParams {
  meta: {
    nextPageCursor: string;
  };
  data: {
    orgId: string;
  };
}

interface Membership {
  id: string;
  org: {
    id: string;
    displayName: string;
    created: number;
    orgTags: string[];
  };
}

const buildQueryUser = (
  fetchType: string,
  params: BuildQueryUserOrgParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.GET_LIST:
      return {
        query: gql`
          query ($cursor: Cursor) {
            user {
              id
              defaultMembership {
                id
                role {
                  admin
                }
                orgId
              }
              membershipList(cursor: $cursor) {
                memberships {
                  id
                  org {
                    id
                    displayName
                    created
                    orgTags
                  }
                }
                pageInfo {
                  endCursor
                }
              }
            }
          }
        `,
        variables: { cursor: params.meta.nextPageCursor },
        parseResponse: (response) => {
          const memberships = response.data.user.membershipList.memberships;
          const defaultOrgId = response.data.user.defaultMembership.orgId;

          return {
            data: memberships.map((m: Membership) => ({
              ...m.org,
              defaultOrgId: defaultOrgId,
              membershipId: memberships.id
            })),
            total: memberships.length,
            endCursor: response.data.user.membershipList.pageInfo.endCursor
          };
        }
      };
    case QueryTypes.UPDATE:
      return {
        query: gql`
          mutation updateDefaultMembership(
            $input: UpdateDefaultMembershipInput!
          ) {
            updateDefaultMembership(input: $input) {
              user {
                id
                defaultMembership {
                  id
                  org {
                    id
                  }
                }
                membershipList {
                  memberships {
                    id
                    org {
                      id
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          input: {
            orgId: params.data.orgId
          }
        },
        parseResponse: (response) => {
          return {
            data: response.data.updateDefaultMembership.user
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryUser;
