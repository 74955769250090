import { useState } from "react";
import {
  Create,
  required,
  SaveButton,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
  useRecordContext,
  Form,
  useRefresh
} from "react-admin";
import { Box, Typography } from "@mui/material";
import mixpanel from "mixpanel-browser";

import { Logger } from "../../../clients/logger";

import { runeTheme } from "../../common/RuneTheme";
import iconSearch from "../../../shared/icon/search.svg";
import iconAddPatient from "../../../shared/icon/add-patient.svg";
import RuneCancelButton from "../../common/buttons/RuneCancelButton";

enum FindPatientStatus {
  UNKNOWN_PATIENT = "UNKNOWN_PATIENT",
  PATIENT_FOUND = "PATIENT_FOUND",
  PATIENT_NOT_FOUND = "PATIENT_NOT_FOUND",
  PATIENT_NOT_ADDED = "PATIENT_NOT_ADDED"
}

interface AddPatientToProjectProps {
  addPatientState: string;
  setAddPatientState: (arg0: string) => void;
}

interface FindPatientButtonProps {
  addPatientState: string;
  setAddPatientState: (arg0: string) => void;
}

const AddPatientToProjectToolbar = (props: AddPatientToProjectProps) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const record = useRecordContext();
  const refresh = useRefresh();

  const FindPatientButton = (props: FindPatientButtonProps) => {
    const record = useRecordContext();
    const logger = Logger();

    return (
      <SaveButton
        label="Find"
        variant="contained"
        type="button"
        icon={<img src={iconSearch} alt="" style={{ height: "24px" }} />}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "12px 16px",
          gap: "4px",
          height: "48px",
          background: runeTheme.palette.primary.main,
          borderRadius: "8px",
          boxShadow: "none",

          fontFamily: "Work Sans",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "16px",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
          color: runeTheme.palette.background.light
        }}
        mutationOptions={{
          onSuccess(data, variables) {
            mixpanel.track("FindPatientSuccessful");
            record.patient_codename = data.patient.codeName;
            record.patient_id = data.patient.id;
            record.device_id = variables.data.device_id;
            record.status = FindPatientStatus.PATIENT_FOUND;
            props.setAddPatientState(FindPatientStatus.PATIENT_FOUND);
            notify(`Patient ${record.patient_codename} found!`, {
              type: "success"
            });
            refresh();
          },
          // https://runelabs.atlassian.net/browse/SW-2470
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          onError(error: any) {
            mixpanel.track("FindPatientFailed");
            logger.warning(`Patient not found: ${error.message}`);
            record.status = FindPatientStatus.PATIENT_NOT_FOUND;
            props.setAddPatientState(FindPatientStatus.PATIENT_NOT_FOUND);
            notify(
              "An error occurred. Please make sure the patient is not already in the project.",
              {
                type: "error"
              }
            );
            refresh();
          }
        }}
      ></SaveButton>
    );
  };

  const AddPatientToProjectButton = (props: AddPatientToProjectProps) => {
    const record = useRecordContext();
    const logger = Logger();
    return (
      <SaveButton
        alwaysEnable
        label="Add"
        type="button"
        icon={<img src={iconAddPatient} alt="" style={{ height: "16px" }} />}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 12px",
          gap: "4px",
          height: "48px",
          background: runeTheme.palette.primary.main,
          borderRadius: "8px",
          fontFamily: "Work Sans",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "14px",
          letterSpacing: "0.1em",
          textTransform: "uppercase",
          color: runeTheme.palette.background.light
        }}
        mutationOptions={{
          onSuccess(data, variables) {
            mixpanel.track("AddPatientToProjectSuccessful");
            notify(`Patient ${data.codeName} added to project!`, {
              type: "success"
            });
            redirect("show", "project", variables.data.project_id);
          },
          // https://runelabs.atlassian.net/browse/SW-2470
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          onError(error: any) {
            mixpanel.track("AddPatientToProjectFailed");
            logger.warning(`Patient not added: ${error.message}`);
            record.status = FindPatientStatus.PATIENT_NOT_ADDED;
            props.setAddPatientState(FindPatientStatus.PATIENT_NOT_ADDED);

            notify(
              "An error occurred. Please make sure the patient or patient codename is not already in the project.",
              {
                type: "error"
              }
            );
          }
        }}
      ></SaveButton>
    );
  };

  return (
    <Toolbar
      className="RuneFormBottomToolbar"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "8px",
        background: runeTheme.palette.background.light,
        paddingBottom: "24px"
      }}
    >
      <RuneCancelButton
        buttonType="SHOW"
        label="CANCEL"
        resource="Project"
        record={{ id: record.project_id }}
      />
      {(record.status === FindPatientStatus.UNKNOWN_PATIENT ||
        record.status === FindPatientStatus.PATIENT_NOT_FOUND) && (
        <FindPatientButton
          addPatientState={props.addPatientState}
          setAddPatientState={props.setAddPatientState}
        />
      )}
      {(record.status === FindPatientStatus.PATIENT_FOUND ||
        record.status === FindPatientStatus.PATIENT_NOT_ADDED) && (
        <AddPatientToProjectButton
          addPatientState={props.addPatientState}
          setAddPatientState={props.setAddPatientState}
        />
      )}
    </Toolbar>
  );
};

const ProjectPatientCreateForm = (props: {
  addPatientState: string;
  setAddPatientState: (arg0: string) => void;
}) => {
  const projectRecord = useRecordContext(props);
  if (!projectRecord) return null;

  const patientFound =
    projectRecord.status === FindPatientStatus.PATIENT_FOUND ||
    projectRecord.status === FindPatientStatus.PATIENT_NOT_ADDED;

  const projectTitle = projectRecord.project_title;

  return (
    <Form>
      <Box sx={{ maxWidth: 570, margin: "1em", borderRadius: "16px" }}>
        <Typography variant="h4" gutterBottom>
          Add patient to project {projectTitle}
        </Typography>
        <Typography
          sx={{
            paddingBottom: "24px",
            lineHeight: "140%",
            letterSpacing: "-0.03em"
          }}
        >
          To add a patient, please enter their Device ID below. The ID can be
          found in the StrivePD application.
        </Typography>

        <TextInput
          variant="outlined"
          source="device_id"
          label="Device ID"
          disabled={patientFound}
          validate={[required()]}
          fullWidth
        />

        {patientFound && (
          <TextInput
            variant="outlined"
            source="patient_codename"
            label="Define Patient Codename"
            fullWidth
          />
        )}
      </Box>

      <div hidden>
        HIDDEN
        <TextInput source="status" label="status" fullWidth />
        <TextInput
          variant="outlined"
          source="project_id"
          label="Project ID"
          validate={[required()]}
          fullWidth
          hidden={true}
        />
        <TextInput
          variant="outlined"
          source="patient_id"
          label="Patient ID"
          fullWidth
        />
      </div>

      <AddPatientToProjectToolbar
        addPatientState={props.addPatientState}
        setAddPatientState={props.setAddPatientState}
      />
    </Form>
  );
};

const ProjectPatientCreate = () => {
  const [addPatientState, setAddPatientState] = useState(
    FindPatientStatus.UNKNOWN_PATIENT.toString()
  );

  mixpanel.track("ProjectPatientCreateForm");
  return (
    <Create sx={{ margin: "5em auto" }} title="Add a patient to the project">
      <>
        <ProjectPatientCreateForm
          addPatientState={addPatientState}
          setAddPatientState={setAddPatientState}
        />
      </>
    </Create>
  );
};

export default ProjectPatientCreate;
