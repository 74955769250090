import { useEffect, useState } from "react";
import buildGraphQLProvider from "ra-data-graphql";
import {
  Admin,
  CustomRoutes,
  DataProvider,
  Resource,
  AuthProvider,
  fetchUtils
} from "react-admin";
import { Route } from "react-router-dom";
import { Provider } from "@rollbar/react";
import { setContext } from "@apollo/client/link/context";
import "./App.css";
import { createGraphqlClient } from "./clients/carrotGraph";
import { runeTheme } from "./components/common/RuneTheme";
import { buildQuery, wrapDataProvider } from "./providers/data/buildQuery";
import getAccessToken from "./providers/auth/getAccessToken";
import PatientShow from "./components/resources/patient/PatientShow/PatientShow";
import ProjectList from "./components/resources/project/ProjectList";
import ProjectShow from "./components/resources/project/ProjectShow/ProjectShow";
import StudyLayout from "./components/StudyLayout";
import ProjectCreate from "./components/resources/project/ProjectCreate";
import ProjectPatientCreate from "./components/resources/projectpatient/ProjectPatientCreate";
import { Amplify } from "aws-amplify";
import DeviceList from "./components/resources/device/DeviceList";
import MemberList from "./components/resources/member/MemberList";
import MemberShow from "./components/resources/member/MemberShow/MemberShow";

import { buildAuthProvider } from "./providers/auth";
import LoginPage from "./pages/LoginPage";
import ProjectEdit from "./components/resources/project/ProjectEdit";
import MemberEdit from "./components/resources/member/MemberShow/MemberEdit";

import { ContactPage } from "./pages/ContactPage";
import { DocumentationPage } from "./pages/DocumentationPage";
import { HelpPage } from "./pages/HelpPage";

import OrgShow from "./components/resources/org/OrgShow/OrgShow";
import OrgList from "./components/resources/org/OrgList";

import mixpanel from "mixpanel-browser";
import { USE_SSO } from "./constants";
import UserAccessTokenList from "./components/resources/useraccesstoken/UserAccessTokenList";

const createRollbarConfig = function (
  environment: string,
  accessToken: string,
  enabled: boolean
) {
  return {
    accessToken,
    environment,
    enabled,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: "1.0.0",
          source_map_enabled: true,
          guess_uncaught_frames: true
        }
      }
    }
  };
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
const config = (window as any).Rune.Carrot.config;
const ssoConfig = window.localStorage.getItem(USE_SSO);
const awsConfig =
  ssoConfig === "true" ? config.aws.sso.config : config.aws.user.config;
Amplify.configure(awsConfig);

function App() {
  // Declare window as type any to remove error accessing custom variables
  // https://runelabs.atlassian.net/browse/SW-2470
  const rollbarConfig = config.rollbar;
  const mixpanelConfig = config.mixpanel;

  const rollbar = createRollbarConfig(
    rollbarConfig.environment,
    rollbarConfig.accessToken,
    rollbarConfig.enabled
  );

  mixpanel.init(mixpanelConfig.projectToken, { debug: mixpanelConfig.debug });

  const [dataProvider, setDataProvider] = useState<DataProvider | null>(null);
  const [graphqlClient, setGraphqlClient] = useState<any | null>(null);
  const [authProvider, setAuthProvider] = useState<AuthProvider | null>(null);

  useEffect(() => {
    const authLink = setContext(async (_, { headers }) => {
      const accessToken = await getAccessToken();
      return {
        headers: {
          ...headers,
          "X-Rune-User-Access-Token": accessToken
        }
      };
    });

    const config = (window as any).Rune.Carrot.config;
    const carrotGraphConfig = config.carrotGraph;

    const graphqlClient = createGraphqlClient(
      carrotGraphConfig.host,
      carrotGraphConfig.port,
      carrotGraphConfig.secure,
      authLink
    );
    setGraphqlClient(() => graphqlClient);

    const carrotStreamBaseUrl = carrotGraphConfig.host.replace(
      "graph",
      "https://stream"
    );
    const httpClient = async (
      url: string,
      options: fetchUtils.Options = {}
    ) => {
      const customHeaders = (options.headers ||
        new Headers({
          Accept: "application/json"
        })) as Headers;
      // add your own headers here
      const accessToken = await getAccessToken();
      customHeaders.set("X-Rune-User-Access-Token", accessToken);
      options.headers = customHeaders;
      const { status, headers, body, json } = await fetchUtils.fetchJson(
        carrotStreamBaseUrl + url,
        options
      );
      return { status, headers, body, json };
    };

    buildGraphQLProvider({
      client: graphqlClient,
      buildQuery: buildQuery
    }).then((graphQlDataProvider) =>
      setDataProvider(() => wrapDataProvider(graphQlDataProvider, httpClient))
    );

    const authProvider = buildAuthProvider({ graphqlClient: graphqlClient });
    setAuthProvider(authProvider);
  }, []);

  if (!dataProvider || !graphqlClient || !authProvider) {
    return <div>Loading... </div>;
  }

  return (
    <Provider config={rollbar}>
      <Admin
        loginPage={LoginPage}
        dataProvider={dataProvider}
        layout={StudyLayout}
        theme={runeTheme}
        authProvider={authProvider}
        requireAuth
      >
        <Resource
          name="Project"
          list={ProjectList}
          show={ProjectShow}
          create={ProjectCreate}
          edit={ProjectEdit}
        />
        <Resource name="Patient" show={PatientShow} />
        <Resource name="ProjectPatient" create={ProjectPatientCreate} />
        <Resource name="Org" list={OrgList} show={OrgShow} />
        <Resource name="Device" list={DeviceList} />
        <Resource
          name="Member"
          list={MemberList}
          show={MemberShow}
          edit={MemberEdit}
        />
        <Resource name="UserAccessToken" list={UserAccessTokenList} />
        <CustomRoutes>
          <Route path="/help" element={<HelpPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/documentation" element={<DocumentationPage />} />
        </CustomRoutes>
      </Admin>
    </Provider>
  );
}

export default App;
