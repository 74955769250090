import React from "react";
import { useRecordContext } from "react-admin";
import { makeStyles } from "@mui/styles";
import { get } from "lodash";
import {
  DATA_AVAILABILITY_THRESHOLD_DAYS,
  DATA_AVAILABILITY_AT_RISK_THRESHOLD_DAYS
} from "../../constants";
import RedFlagIcon from "../../shared/icon/red-flag.svg";
import YellowFlagIcon from "../../shared/icon/yellow-flag.svg";
import { useFlags } from "src/clients/launchDarkly";

const useStyles = makeStyles({
  compliant: {
    color: "black"
  },
  atRisk: {
    fontWeight: "500"
  },
  notCompliant: {
    fontWeight: "600"
  }
});

export const DataRecencyField = (props: {
  source: string;
  label: string;
  category: string;
}) => {
  const classes = useStyles();
  const record = useRecordContext();

  const value = get(record, props.source);
  const { patientStartEndDate } = useFlags();
  const isActive = patientStartEndDate ? record.isActive : true;

  let fieldInfo = {
    category: props.category,
    lastDate: "Fetching...",
    daysAgo: -1,
    complianceStatus: "compliant"
  };

  if (typeof value === "undefined" || value === null) {
    fieldInfo = {
      category: props.category,
      lastDate: "Never",
      daysAgo: -1,
      complianceStatus: "notCompliant"
    };
  } else {
    const lastDate = new Date(value * 1000);
    // if there's a start/end date on the org membership, but patient has no data within it,
    // lastDate should be 'Never' and complianceStatus should be 'notCompliant'.
    if (patientStartEndDate && value < record.projectStartTime) {
      fieldInfo = {
        category: props.category,
        lastDate: "Never",
        daysAgo: -1,
        complianceStatus: "notCompliant"
      };
    } else {
      const daysAgo = Math.round(
        (new Date().getTime() - lastDate.getTime()) / (1000 * 3600 * 24)
      );
      const complianceStatus =
        daysAgo > DATA_AVAILABILITY_THRESHOLD_DAYS
          ? "notCompliant"
          : daysAgo > DATA_AVAILABILITY_AT_RISK_THRESHOLD_DAYS
          ? "atRisk"
          : "compliant";

      fieldInfo = {
        category: props.category,
        lastDate: lastDate.toLocaleDateString(),
        daysAgo,
        complianceStatus
      };
    }
  }

  return (
    <div
      style={{
        flex: "1 0 30%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
      }}
    >
      {fieldInfo.complianceStatus === "notCompliant" && isActive && (
        <img
          src={RedFlagIcon}
          alt="Red Flag"
          style={{ width: "20px", height: "20px", marginRight: "5px" }}
        />
      )}
      {fieldInfo.complianceStatus === "atRisk" && isActive && (
        <img
          src={YellowFlagIcon}
          alt="Yellow Flag"
          style={{ width: "20px", height: "20px", marginRight: "5px" }}
        />
      )}
      {isActive ? (
        <span
          className={
            classes[fieldInfo.complianceStatus as keyof typeof classes]
          }
        >
          {fieldInfo.daysAgo === -1
            ? "Never"
            : `${fieldInfo.daysAgo} day${
                fieldInfo.daysAgo === 1 ? "" : "s"
              } ago`}
        </span>
      ) : (
        <span
          className={
            classes[fieldInfo.complianceStatus as keyof typeof classes]
          }
        >
          -
        </span>
      )}
    </div>
  );
};
