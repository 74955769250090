import { Button } from "@mui/material";
import { runeTheme } from "../RuneTheme";

export interface PrimaryActionDialogButtonProps {
  label: string;
  onClick: () => void;
  icon?: string;
}

const PrimaryActionDialogButton = (props: PrimaryActionDialogButtonProps) => {
  const { label, onClick } = props;
  return (
    <Button
      id="menu-button"
      aria-haspopup="true"
      variant="contained"
      disableElevation
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "12px 16px",
        gap: "4px",
        minWidth: "196px",
        height: "48px",
        borderRadius: "8px",
        fontWeight: "600",
        color: runeTheme.palette.button.primary.text,
        background: runeTheme.palette.button.primary.background,
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          color: runeTheme.palette.button.primary.hoverText,
          background: runeTheme.palette.button.primary.hoverBackground
        }
      }}
    >
      <img
        src={props.icon}
        alt=""
        style={{ height: "24px", padding: "3px 5px" }}
      />
      <span>{label}</span>
    </Button>
  );
};

export default PrimaryActionDialogButton;
