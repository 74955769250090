import React, { useState } from "react";
import { Button, Confirm, useDelete, useRefresh, useNotify } from "react-admin";

import ActionDelete from "@mui/icons-material/Delete";

import { runeTheme } from "../../common/RuneTheme";

import ReferralCode from "./ReferralCode";

export default function RemoveReferralCode(props: {
  referralCode: ReferralCode;
}) {
  const { referralCode } = props;
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  const [deleteOne, { isLoading }] = useDelete();

  const refresh = useRefresh();
  const notify = useNotify();

  const removeCode = () => {
    deleteOne(
      "ReferralCode",
      { id: referralCode.code },
      {
        onSuccess: (data) => {
          if (data.errors && data.errors.length > 0) {
            notify(`Error: ${data.errors[0].message}`, {
              type: "error"
            });
            return;
          }
          refresh();
          notify("Referral code deleted.", {
            type: "info"
          });
        },
        onError(error) {
          console.log(error);
        }
      }
    );
  };

  return (
    <div>
      {`Referral Code: ${referralCode.code} (${referralCode.appMode.displayName})`}
      <Button
        sx={{
          color: runeTheme.palette.button.destructive.hoverBorder,
          height: "48px",
          fontFamily: "Work Sans",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "12px"
        }}
        onClick={handleClickOpen}
        label={""}
      >
        <ActionDelete />
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={`Delete referral code ${referralCode.code}`}
        content={`
          Are you sure you want to delete this code?. 
          Please make sure you have read and understand 
          the confluence page on this workflow before continuing.`}
        onConfirm={removeCode}
        onClose={handleClickClose}
      />
    </div>
  );
}
