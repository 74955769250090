import { styled } from "@mui/material";
import { runeTheme } from "../common/RuneTheme";

const ListContentHeader = styled("div")({
  display: "flex",
  width: "100%",
  minHeight: "100px",
  justifyContent: "space-between",
  marginRight: "32px",
  backgroundColor: "white"
});

const ListContentTitle = styled("div")({
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "32px",
  gap: "20px",
  height: "70px",
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "42px",
  lineHeight: "49px",
  letterSpacing: "-0.03em",
  color: runeTheme.palette.primary.main
});

const ListContentDescription = styled("div")({
  padding: "32px",
  fontStyle: "normal",
  fontWeight: "400"
});

const ListActions = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "16px"
});

export {
  ListContentHeader,
  ListContentTitle,
  ListContentDescription,
  ListActions
};
