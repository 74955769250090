import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import {
  DATA_AVAILABILITY_THRESHOLD_DAYS,
  QueryTypes,
  SEC_PER_DAY
} from "../../constants";
import { isDataOutdated } from "./utilities";
import {
  PatientMetricData,
  filterPatientAccess
} from "./buildQueryProjectPatient";

// https://runelabs.atlassian.net/browse/SW-2470
/* eslint-disable @typescript-eslint/no-explicit-any */

type Patient = {
  id: string;
  createdAt: string;
  realName: string;
  email: string;
  [key: string]: any;
};

const buildQueryCohortPatient = (
  fetchType: string,
  params: any
): BuildQueryResult => {
  let gqlQuery = `mutation($cohortIds: [ID]!) { \n`;
  switch (fetchType) {
    case QueryTypes.CREATE:
      return {
        query: gql`
          mutation ($input: CreateCohortPatientInput!) {
            addPatientToCohort(input: $input) {
              cohortPatient {
                patient {
                  id
                }
                codeName
                createdAt
                updatedAt
                createdBy
                updatedBy
              }
            }
          }
        `,
        variables: {
          input: {
            cohortId: params.data.cohortId,
            patientId: params.data.patientId
          }
        },
        parseResponse: (response) => {
          return {
            data: response.data,
            total: response.data.length
          };
        }
      };
    case QueryTypes.GET_MANY_REFERENCE:
      return {
        query: gql`
          query getCohort(
            $cohortId: ID,
            $metricCategories: [MetricCategoryEnum]
          ) {
            cohort(id: $cohortId) {
              id
              organizationId
              cohortPatientList {
                cohortPatients {
                  patient {
                    id
                    striveUserId
                    createdAt

                    ${
                      params.filter.canSeePII
                        ? "identifiableInfo { realName email }"
                        : ""
                    }
                    appMode {
                      id
                      displayName
                    }
                    patientAccessList {
                        patientAccess {
                            startTime
                            endTime
                            isActive
                            org {
                                id
                            }
                        }
                    }
                  }
                  codeName
                  metricCategoryTimeRanges(metricCategories: $metricCategories) {
                    metricCategory
                    startTime
                    endTime
                  }
                }
                pageInfo {
                  codeNameEndCursor
                  metricsEndCursor
                }
              }
            }
          }
        `,
        variables: {
          cohortId: params.filter.cohortId,
          timeInterval: params.filter.dataAvailability,
          metricCategories: params.filter.metricCategories
        },
        parseResponse: (response) => {
          const dataAvailabilityThresholdDaysInSeconds =
            DATA_AVAILABILITY_THRESHOLD_DAYS * SEC_PER_DAY;
          const cohortPatients =
            response.data.cohort.cohortPatientList.cohortPatients;
          const orgId = response.data.cohort.organizationId;
          const metricsData = cohortPatients.map(
            (cohortPatient: PatientMetricData) => {
              const access = filterPatientAccess(
                cohortPatient.patient.patientAccessList?.patientAccess,
                orgId
              );
              const res: any = {
                ...cohortPatient,
                id: cohortPatient.patient.id,
                createdAt: cohortPatient.patient.createdAt,
                realName: cohortPatient.patient.identifiableInfo?.realName,
                email: cohortPatient.patient.identifiableInfo?.email,
                appMode: cohortPatient.patient.appMode?.displayName,
                projectStartTime: access?.startTime,
                projectEndTime: access?.endTime,
                isActive: access?.isActive
              };

              for (const metricCategoryTimeRange of cohortPatient.metricCategoryTimeRanges) {
                res[metricCategoryTimeRange.metricCategory] =
                  metricCategoryTimeRange.endTime;
              }

              return res;
            }
          );

          const totalPatients = metricsData.length;
          const categoryCounts: { [key: string]: number } = {};
          const noDataForTenDaysPatients = new Set();

          for (const metricCategory of params.filter.metricCategories) {
            categoryCounts[metricCategory] = 0;
          }

          metricsData.forEach((patient: PatientMetricData) => {
            let noDataForAnyCategory = false;

            params.filter.metricCategories.forEach((metricCategory: string) => {
              const endTime = patient[metricCategory];
              if (
                isDataOutdated(endTime, dataAvailabilityThresholdDaysInSeconds)
              ) {
                categoryCounts[metricCategory]++;
                noDataForTenDaysPatients.add(patient.id);
                noDataForAnyCategory = true;
              }
            });

            if (noDataForAnyCategory) {
              noDataForTenDaysPatients.add(patient.id);
            }
          });

          const filteredPatients = metricsData.filter((patient: Patient) => {
            if (params.filter.qcMetricCategory === "ANY") {
              return noDataForTenDaysPatients.has(patient.id);
            } else {
              const endTime = patient[params.filter.qcMetricCategory as string];
              return isDataOutdated(
                endTime,
                dataAvailabilityThresholdDaysInSeconds
              );
            }
          });

          return {
            data: filteredPatients,
            total: totalPatients,
            categoryCounts: categoryCounts,
            noDataForAnyCategoryCount: noDataForTenDaysPatients.size
          };
        }
      };
    case QueryTypes.DELETE_MANY:
      params.ids.forEach((id: string, index: number) => {
        gqlQuery +=
          "aliasDelete" +
          index +
          ': removePatientFromCohorts(cohortIds: $cohortIds, patientId: "' +
          id +
          '") { status } \n';
      });
      gqlQuery += "}\n";

      return {
        query: gql(gqlQuery),
        variables: {
          cohortIds: [params.meta.cohortId]
        },
        parseResponse: () => {
          return { data: [] };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryCohortPatient;
