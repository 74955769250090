import { useIdleTimer } from "react-idle-timer";
import { useLogout, useGetIdentity } from "react-admin";
import { MILLI_SEC_PER_HOUR } from "../../constants";

// Set idle timeout for logged in super admin users.
const useIdleTimeout = () => {
  const { data: user } = useGetIdentity();
  const isSuperAdmin = user?.defaultMembership?.role.superAdmin;
  const logout = useLogout();
  let onIdle = () => {
    return;
  };

  // If super admin, logout after 1 hour of inactivity.
  if (isSuperAdmin) {
    onIdle = logout;
  }

  const idleTimer = useIdleTimer({
    timeout: MILLI_SEC_PER_HOUR,
    onIdle: onIdle,
    debounce: 500
  });

  return idleTimer;
};

export default useIdleTimeout;
