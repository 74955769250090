import { FunctionField, RaRecord } from "react-admin";

import { runeTheme } from "../../common/RuneTheme";
import { Badge } from "../../fields/Badge";
import { OrgTag } from "../../../constants";

interface OrgTagBadgeProps {
  source: string;
  label?: string;
}

export const OrgTagBadge = (props: OrgTagBadgeProps) => {
  return (
    <FunctionField
      {...props}
      render={(record: RaRecord) => {
        const orgTags = record.orgTags;

        if (orgTags === null || orgTags === undefined) {
          return null;
        }

        return orgTags.map((tag: string, index: number) => {
          let badgeVariant;

          switch (tag) {
            case OrgTag.AGGREGATION:
              badgeVariant = runeTheme.palette.badge.orgTagAggregation;
              break;
            case OrgTag.CLINICAL:
              badgeVariant = runeTheme.palette.badge.orgTagClinical;
              break;
            case OrgTag.DEMO:
              badgeVariant = runeTheme.palette.badge.orgTagDemo;
              break;
            case OrgTag.INTERNAL:
              badgeVariant = runeTheme.palette.badge.orgTagInternal;
              break;
            case OrgTag.RESEARCH:
              badgeVariant = runeTheme.palette.badge.orgTagResearch;
              break;
            case OrgTag.RESEARCH_RESTRICTED:
              badgeVariant = runeTheme.palette.badge.orgTagResearchRestricted;
              break;
            default:
              badgeVariant = runeTheme.palette.badge.default;
          }

          return (
            <Badge
              key={index}
              bgColor={badgeVariant}
              display="inline"
              sx={{ marginRight: "5px" }}
            >
              {tag}
            </Badge>
          );
        });
      }}
    />
  );
};
