import * as React from "react";
import {
  TabbedShowLayout,
  Tab,
  TextField,
  Labeled,
  useRecordContext,
  useCreatePath
} from "react-admin";
import { Typography } from "@mui/material";

import { RuneDateField } from "../../../fields/RuneDateField";
import { BooleanInverseBadgeField } from "../../../fields/BooleanInverseBadgeField";
import {
  DetailPageGridContainer,
  DetailPageTopRow,
  DetailPageBottomRow,
  DetailPageCol
} from "../../../common/DetailPageGrid";
import TertiaryActionButton from "../../../common/buttons/TertiaryActionButton";
import iconEdit from "../../../../shared/icon/edit.svg";
import { Member } from "../../../../types";

const EditMemberButton = () => {
  const member = useRecordContext<Member>();
  const createPath = useCreatePath();
  return (
    <TertiaryActionButton
      link={createPath({ type: "edit", resource: "Member", id: member.id })}
      state={{
        record: {
          member_id: member.id
        }
      }}
      icon={iconEdit}
      label="Edit Member"
    />
  );
};

const MemberDetail = () => {
  const member = useRecordContext<Member>();
  const dateOptions = { year: "numeric", month: "short", day: "numeric" };
  return (
    <TabbedShowLayout>
      <Tab label="Overview" sx={{ textTransform: "capitalize" }}>
        <DetailPageGridContainer>
          <DetailPageTopRow>
            <DetailPageCol sx={{ width: "450px" }}>
              <Labeled label="DISPLAY NAME">
                {member.user.displayName !== null ? (
                  <TextField
                    source="user.displayName"
                    variant="h3"
                    sx={{ fontWeight: "400" }}
                  />
                ) : (
                  <Typography>--</Typography>
                )}
              </Labeled>
            </DetailPageCol>

            <DetailPageCol>
              <Labeled label="CREATED ON">
                <RuneDateField
                  source="created"
                  showTime={true}
                  dateFmtOptions={dateOptions}
                />
              </Labeled>
            </DetailPageCol>
          </DetailPageTopRow>

          <DetailPageBottomRow>
            <DetailPageCol sx={{ width: "450px" }}>
              <Labeled label="USER EMAIL">
                <TextField source="user.email" />
              </Labeled>

              <Labeled label="ORGANIZATION">
                <TextField source="org.displayName" />
              </Labeled>

              <Labeled label="USER ID">
                <TextField source="user.id" />
              </Labeled>

              <Labeled label="ENABLED">
                <BooleanInverseBadgeField label="Enabled" source="disabled" />
              </Labeled>
            </DetailPageCol>

            <DetailPageCol>
              <Labeled label="ROLE">
                <TextField source="role.displayName" />
              </Labeled>

              <Labeled label="STATUS">
                <TextField source="status.displayName" />
              </Labeled>

              <Labeled label="MEMBERSHIP ID">
                <TextField source="id" />
              </Labeled>
            </DetailPageCol>
          </DetailPageBottomRow>

          <EditMemberButton />
        </DetailPageGridContainer>
      </Tab>
    </TabbedShowLayout>
  );
};

const MemberTabs = () => {
  return <MemberDetail />;
};

export default MemberTabs;
