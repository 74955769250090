import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  required,
  Toolbar,
  useCreate,
  useRedirect,
  maxValue,
  minValue
} from "react-admin";

import { Box, Typography } from "@mui/material";

import mixpanel from "mixpanel-browser";

import { MIN_PROJECT_START_DATE } from "../../../constants";
import RuneSaveButton from "../../common/buttons/RuneSaveButton";
import RuneCancelButton from "../../common/buttons/RuneCancelButton";
import iconAddProject from "../../../shared/icon/add-project.svg";
import iconCancel from "../../../shared/icon/cancel.svg";

const ProjectCreateToolbar = () => {
  return (
    <Toolbar
      className="RuneFormBottomToolbar"
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "8px"
      }}
    >
      <RuneCancelButton label="CANCEL" icon={iconCancel} buttonType="LIST" />
      <RuneSaveButton label="CREATE" icon={iconAddProject} />
    </Toolbar>
  );
};

const ProjectCreate = () => {
  mixpanel.track("ProjectCreateForm");
  const [create] = useCreate();

  const redirect = useRedirect();

  // https://runelabs.atlassian.net/browse/SW-2470
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const projectSave = (data: any) => {
    mixpanel.track("ProjectCreateSubmit");
    create(
      "Project",
      { data },
      {
        onSuccess(data) {
          redirect("show", "Project", data.id);
        }
      }
    );
  };
  return (
    <Create sx={{ margin: "5em auto" }} redirect="show">
      <SimpleForm
        sx={{ maxWidth: 570 }}
        toolbar={<ProjectCreateToolbar />}
        onSubmit={projectSave}
      >
        <Typography variant="h5" gutterBottom>
          Create Project
        </Typography>
        <Typography>
          To create a new project, please fill out the details below.
        </Typography>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={3} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              variant="outlined"
              source="title"
              label="Project Name"
              isRequired
              validate={required()}
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              variant="outlined"
              source="type"
              label="Project Type"
              choices={[
                { id: "EXPLORATORY", name: "EXPLORATORY" },
                { id: "CLINICAL_TRIAL", name: "CLINICAL TRIAL" },
                { id: "RETROSPECTIVE_STUDY", name: "RETROSPECTIVE STUDY" },
                { id: "PROSPECTIVE_STUDY", name: "PROSPECTIVE STUDY" },
                { id: "SANDBOX", name: "SANDBOX" }
              ]}
              isRequired
              validate={required()}
            />
          </Box>
        </Box>
        <TextInput
          variant="outlined"
          source="description"
          multiline={true}
          label="Short description"
          fullWidth
        />

        <DateInput
          variant="outlined"
          label="Start Date"
          source="startedAt"
          defaultValue={new Date()}
          parse={(val) => new Date(val + "T00:00:00")}
          validate={[
            required(),
            minValue(MIN_PROJECT_START_DATE),
            maxValue(new Date())
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default ProjectCreate;
