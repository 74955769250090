import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  DialogActions,
  Button as MuiButton
} from "@mui/material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography
} from "@mui/material";

import { useState } from "react";
import { Button } from "react-admin";
import { runeTheme } from "src/components/common/RuneTheme";

export interface DataTypeConfig {
  enabled: boolean;
  threshold?: number;
}

interface VisualizationConfig {
  highlightMissingData: boolean;
}

export interface ProjectDisplayConfig {
  dataTypes: {
    tremor_dyskinesia: DataTypeConfig;
    mobility: DataTypeConfig;
    sleep: DataTypeConfig;
    medication: DataTypeConfig;
  };
  visualization: VisualizationConfig;
}

interface ProjectDisplayConfigurationDialogProps {
  projectDisplayConfig: ProjectDisplayConfig;
  setProjectDisplayConfig: (config: ProjectDisplayConfig) => void;
}

export const ProjectDisplayConfigurationDialog = ({
  projectDisplayConfig,
  setProjectDisplayConfig
}: ProjectDisplayConfigurationDialogProps) => {
  const [open, setOpen] = useState(false);
  const [tempConfig, setTempConfig] = useState(projectDisplayConfig);

  const handleConfigurationChange = (config: ProjectDisplayConfig) => {
    setTempConfig(config);
  };

  const handleClickOpen = () => {
    setTempConfig(projectDisplayConfig);
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setProjectDisplayConfig(tempConfig);
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{
          fontSize: "16px",
          lineHeight: "19px",
          letterSpacing: "-0.03em",
          textTransform: "capitalize",
          fontWeight: "600",
          color: runeTheme.palette.button.tertiary.text
        }}
        label="Configure Display"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClickClose}
        fullWidth
        sx={{ margin: "auto" }}
      >
        <DialogTitle>Display Configuration</DialogTitle>
        <DialogContent>
          <ProjectDisplayConfigurationForm
            projectDisplayConfig={tempConfig}
            onConfigurationChange={handleConfigurationChange}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClickClose}>Cancel</MuiButton>
          <MuiButton onClick={handleSave} color="primary">
            Save
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface ProjectDisplayConfigurationFormProps {
  projectDisplayConfig: ProjectDisplayConfig;
  onConfigurationChange: (config: ProjectDisplayConfig) => void;
}

const ProjectDisplayConfigurationForm = ({
  projectDisplayConfig,
  onConfigurationChange
}: ProjectDisplayConfigurationFormProps) => {
  const handleCheckboxChange =
    (dataType: keyof ProjectDisplayConfig["dataTypes"]) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedConfig = {
        ...projectDisplayConfig,
        dataTypes: {
          ...projectDisplayConfig.dataTypes,
          [dataType]: {
            ...projectDisplayConfig.dataTypes[dataType],
            enabled: event.target.checked
          }
        }
      };
      onConfigurationChange(updatedConfig);
    };

  const handleThresholdChange =
    (dataType: keyof ProjectDisplayConfig["dataTypes"]) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedConfig = {
        ...projectDisplayConfig,
        dataTypes: {
          ...projectDisplayConfig.dataTypes,
          [dataType]: {
            ...projectDisplayConfig.dataTypes[dataType],
            threshold: parseInt(event.target.value)
          }
        }
      };
      onConfigurationChange(updatedConfig);
    };

  const getUnitForCategory = (category: string): string => {
    const units: { [key: string]: string } = {
      tremor_dyskinesia: "h/day",
      mobility: "h/day",
      sleep: "h/day",
      medication: "count/day"
      // Add other categories and their units here
    };
    return units[category] || "N/A";
  };

  const handleVisualizationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedConfig = {
      ...projectDisplayConfig,
      visualization: {
        ...projectDisplayConfig.visualization,
        highlightMissingData: event.target.checked
      }
    };
    onConfigurationChange(updatedConfig);
  };

  const defaultDataTypes = {
    tremor_dyskinesia: { enabled: false, threshold: 6 },
    mobility: { enabled: false, threshold: 0 },
    sleep: { enabled: false, threshold: 1 },
    medication: { enabled: false, threshold: 0 }
  };

  const mergedDataTypes = {
    ...defaultDataTypes,
    ...projectDisplayConfig.dataTypes
  };

  return (
    <div>
      <Typography variant="h6">Data Options</Typography>
      <Typography>
        Please select the categories of data your project requires and,
        optionally, establish expected data thresholds, to help manage project
        health.
      </Typography>
      <FormGroup>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sm={4}>
            <Typography variant="subtitle1">
              <strong>DATA CATEGORIES</strong>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography variant="subtitle1">
              <strong>EXPECTED DATA</strong>
            </Typography>
          </Grid>
        </Grid>

        {Object.entries(mergedDataTypes).map(([key, config]) => (
          <Grid container spacing={2} alignItems="center" key={key}>
            <Grid item xs={6} sm={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={config.enabled}
                    onChange={handleCheckboxChange(
                      key as keyof ProjectDisplayConfig["dataTypes"]
                    )}
                  />
                }
                label={`${key.charAt(0).toUpperCase() + key.slice(1)}`}
              />
            </Grid>
            {config.enabled && config.threshold !== undefined && (
              <Grid item xs={6} sm={2}>
                <TextField
                  type="number"
                  value={config.threshold}
                  onChange={handleThresholdChange(
                    key as keyof ProjectDisplayConfig["dataTypes"]
                  )}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{
                    shrink: false
                  }}
                  variant="outlined"
                  sx={{
                    borderRadius: "32px"
                  }}
                  disabled
                />
              </Grid>
            )}
            {config.enabled && (
              <Grid item xs={6} sm={2}>
                <Typography>{getUnitForCategory(key)}</Typography>
              </Grid>
            )}
          </Grid>
        ))}
      </FormGroup>

      <Typography variant="h6">Visualization</Typography>
      <Typography>Configure visualization options for your project.</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={projectDisplayConfig.visualization.highlightMissingData}
              onChange={handleVisualizationChange}
            />
          }
          label="Highlight missing data"
        />
      </FormGroup>
    </div>
  );
};
