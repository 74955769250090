import React, { useState, useEffect } from "react";
import {
  Button,
  Edit,
  SimpleForm,
  TextInput,
  required,
  useRefresh,
  useNotify,
  useUpdate,
  Toolbar
} from "react-admin";

import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { runeTheme } from "../../common/RuneTheme";
import iconCheckMark from "../../../shared/icon/checkmark.svg";

import RuneSaveButton from "../../common/buttons/RuneSaveButton";
import SecondaryActionButton from "../../common/buttons/SecondaryActionButton";

const Wrapper = styled("div")({
  boxShadow: "none"
});

interface AppMode {
  id: number;
  name: string;
}

function UpdateProjectPatientToolbar(props: { handleClickClose: () => void }) {
  return (
    <Toolbar
      sx={{
        background: runeTheme.palette.background.light,
        display: "flex",
        justifyContent: "flex-end"
      }}
      disableGutters={true}
    >
      <SecondaryActionButton onClick={props.handleClickClose} label="Cancel" />
      <RuneSaveButton label="Save" icon={iconCheckMark} />
    </Toolbar>
  );
}

function ProjectPatientUpdateForm(props: {
  projectId: string;
  handleClickClose: () => void;
}) {
  const { handleClickClose, projectId } = props;
  const refresh = useRefresh();
  const notify = useNotify();

  const [update] = useUpdate();

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const save = (data: any) => {
    data.projectId = projectId;

    update(
      "ProjectPatient",
      { data },
      {
        mutationMode: "pessimistic",
        onSuccess(data) {
          if (data.errors && data.errors.length > 0) {
            notify(`Error: ${data.errors[0].message}`, {
              type: "error"
            });
            return;
          }
          refresh();
          notify("Patient project details successfully updated.", {
            type: "info"
          });
        },
        onError(error) {
          if (error instanceof Error) {
            notify(error.message, {
              type: "error"
            });
          } else {
            notify(
              "Unknown error occurred. Unable to update patient project details.",
              {
                type: "error"
              }
            );
          }
        }
      }
    );
  };

  return (
    <SimpleForm
      sx={{ maxWidth: 570 }}
      onSubmit={save}
      toolbar={UpdateProjectPatientToolbar({ handleClickClose })}
    >
      <TextInput
        variant="outlined"
        source="codeName"
        id="codeName"
        label="Code Name"
        fullWidth
        validate={required()}
      />
    </SimpleForm>
  );
}

export default function ProjectPatientUpdateDialog(props: {
  projectId: string;
}) {
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleClickOpen = (e: any) => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{
          display: "inline"
        }}
        onClick={handleClickOpen}
        label={"Edit"}
      />
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Update Patient Project Details</DialogTitle>
        <DialogContent>
          <ProjectPatientUpdateForm
            handleClickClose={handleClickClose}
            projectId={props.projectId}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
