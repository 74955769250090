import { ReactNode } from "react";
import { Button } from "@mui/material";
import { runeTheme } from "../../common/RuneTheme";

export interface SecondaryActionButtonProps {
  label: string;
  onClick: () => void;
  icon?: ReactNode;
}

const SecondaryActionButton = (props: SecondaryActionButtonProps) => {
  const { label, onClick, icon } = props;
  return (
    <Button
      id="menu-button"
      aria-haspopup="true"
      variant="contained"
      disableElevation
      onClick={onClick}
      sx={{
        marginRight: "16px",
        height: "48px",
        background: runeTheme.palette.button.secondary.background,
        borderRadius: "8px",
        lineHeight: "16px",
        textTransform: "uppercase",
        color: runeTheme.palette.button.secondary.text,
        fontWeight: "600",
        borderColor: runeTheme.palette.button.secondary.border,
        border: "1px solid",
        "&:hover": {
          color: runeTheme.palette.button.secondary.hoverText,
          background: runeTheme.palette.button.secondary.hoverBackground,
          borderColor: runeTheme.palette.button.secondary.hoverBorder,
          border: "1px solid"
        }
      }}
    >
      {icon && icon}
      <span>{label}</span>
    </Button>
  );
};

export default SecondaryActionButton;
