import * as React from "react";
import { memo, FC, ReactElement } from "react";
import { useRecordContext } from "react-admin";
import PropTypes from "prop-types";

import { Typography, TypographyProps } from "@mui/material";
import { TableCellProps } from "@mui/material/TableCell";
import { useTranslate, RaRecord } from "ra-core";

import get from "lodash/get";
import set from "lodash/set";

import { runeTheme } from "../common/RuneTheme";

export const sanitizeFieldRestProps = <T extends Record<string, unknown>>(
  props: T
): T => {
  const { ...restProps } = props;
  return restProps;
};

type TextAlign = TableCellProps["align"];
type SortOrder = "ASC" | "DESC";

// https://runelabs.atlassian.net/browse/SW-2470
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export interface FieldProps<RecordType extends RaRecord = any>
  extends PublicFieldProps,
    InjectedFieldProps<RecordType> {}

export interface PublicFieldProps {
  sortBy?: string;
  sortByOrder?: SortOrder;
  source?: string;
  label?: string | ReactElement | boolean;
  sortable?: boolean;
  className?: string;
  cellClassName?: string;
  headerClassName?: string;
  /*
   * @deprecated this property is not used anymore
   */
  formClassName?: string;
  textAlign?: TextAlign;
  emptyText?: string;
  fullWidth?: boolean;
}

// https://runelabs.atlassian.net/browse/SW-2470
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export interface InjectedFieldProps<RecordType = any> {
  record?: RecordType;
  resource?: string;
}

export const fieldPropTypes = {
  sortBy: PropTypes.string,
  sortByOrder: PropTypes.oneOf<SortOrder>(["ASC", "DESC"]),
  source: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool
  ]),
  sortable: PropTypes.bool,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  textAlign: PropTypes.oneOf<TextAlign>([
    "inherit",
    "left",
    "center",
    "right",
    "justify"
  ]),
  emptyText: PropTypes.string
};

// RuneDateField is a wrapper around DateField that passes multiplies the source
// field by 1000. Carrot Graph dates are in Unix seconds but DateField expects
// Unix milliseconds.
export const RuneDateField = (props: RuneInternalDateFieldProps) => {
  const source = props.source ? props.source : "";
  const record = useRecordContext();
  const unixSecondsDate = get(record, source);
  const unixMillisecondsDate = unixSecondsDate * 1000;

  const newRecord = JSON.parse(JSON.stringify(record));

  set(newRecord, source, unixMillisecondsDate);
  return <RuneInternalDateField {...props} record={newRecord} emptyText="-" />;
};

/**
 * Display a date value as a locale string.
 *
 * Uses Intl.DateTimeFormat() if available, passing the locales and options props as arguments.
 * If Intl is not available, it outputs date as is (and ignores the locales and options props).
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
 * @example
 * <DateField source="published_at" />
 * // renders the record { id: 1234, published_at: new Date('2012-11-07') } as
 * <span>07/11/2012</span>
 *
 * <DateField source="published_at" className="red" />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span class="red">07/11/2012</span>
 *
 * <DateField source="share" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span>Wednesday, November 7, 2012</span>
 *
 * <DateField source="price" locales="fr-FR" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span>mercredi 7 novembre 2012</span>
 */
export const RuneInternalDateField: FC<RuneInternalDateFieldProps> = memo(
  (props) => {
    const {
      className,
      emptyText,
      locales,
      dateFmtOptions,
      timeFmtOptions,
      showTime = false,
      showDate = true,
      source,
      ...rest
    } = props;
    const translate = useTranslate();

    if (!showTime && !showDate) {
      throw new Error(
        "<DateField> cannot have showTime and showDate false at the same time"
      );
    }

    const record = useRecordContext(props);
    if (!record) {
      return null;
    }

    const sanitizedSource = source ? source : "";

    const value = get(record, sanitizedSource);
    if (value == null || value === "" || value === 0 || isNaN(value)) {
      return emptyText ? (
        <Typography
          component="span"
          variant="body2"
          className={className}
          {...sanitizeFieldRestProps(rest)}
        >
          {emptyText && translate(emptyText, { _: emptyText })}
        </Typography>
      ) : null;
    }

    const date = value instanceof Date ? value : new Date(value);
    let dateOptions = dateFmtOptions;
    const timeOptions = timeFmtOptions;

    if (
      typeof value === "string" &&
      value.length <= 10 &&
      !showTime &&
      !dateFmtOptions
    ) {
      // Input is a date string (e.g. '2022-02-15') without time and time zone.
      // Force timezone to UTC to fix issue with people in negative time zones
      // who may see a different date when calling toLocaleDateString().
      dateOptions = { timeZone: "UTC" };
    }
    let dateOnlyString = "";
    let timeOnlyString = "";
    const dateString = "";

    dateOnlyString = toLocaleStringSupportsLocales
      ? date.toLocaleDateString(locales, dateOptions)
      : date.toLocaleDateString();

    timeOnlyString = toLocaleStringSupportsLocales
      ? date.toLocaleTimeString(locales, timeOptions)
      : date.toLocaleTimeString();

    if (showTime && showDate) {
      return (
        <Typography
          component="span"
          variant="body2"
          className={className}
          {...sanitizeFieldRestProps(rest)}
        >
          {dateOnlyString}
          <br />
          <span style={{ color: runeTheme.palette.dateTime.secondary }}>
            {timeOnlyString}
          </span>
        </Typography>
      );
    } else if (showDate) {
      return (
        <Typography
          component="span"
          variant="body2"
          className={className}
          {...sanitizeFieldRestProps(rest)}
        >
          {dateOnlyString}
        </Typography>
      );
    } else if (showTime) {
      return (
        <Typography
          component="span"
          variant="body2"
          className={className}
          {...sanitizeFieldRestProps(rest)}
        >
          {timeOnlyString}
        </Typography>
      );
    }

    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {dateString}
      </Typography>
    );
  }
);

RuneInternalDateField.propTypes = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
  locales: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  options: PropTypes.object,
  showTime: PropTypes.bool,
  showDate: PropTypes.bool
};

RuneInternalDateField.displayName = "RuneInternalDateField";

export interface RuneInternalDateFieldProps
  extends PublicFieldProps,
    InjectedFieldProps,
    Omit<TypographyProps, "textAlign"> {
  locales?: string | string[];
  dateFmtOptions?: object;
  timeFmtOptions?: object;
  showTime?: boolean;
  showDate?: boolean;
}

const toLocaleStringSupportsLocales = (() => {
  // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
  try {
    new Date().toLocaleString("i");
  } catch (error) {
    return error instanceof RangeError;
  }
  return false;
})();
