import { List, Datagrid, TextField, DateField } from "react-admin";
import { Box } from "@mui/material";
import mixpanel from "mixpanel-browser";

import { runeTheme } from "../../common/RuneTheme";
import iconAddProject from "../../../shared/icon/add-project.svg";
import PrimaryActionButton from "../../common/buttons/PrimaryActionButton";
import { StudyDatagridHeader } from "../../common/StudyDataGrid/StudyDatagridHeader";
import {
  ListContentHeader,
  ListContentTitle,
  ListActions
} from "../../common/ListContentHeader";

const CreateNewProjectButton = () => {
  return (
    <PrimaryActionButton
      link="/Project/create"
      state={{}}
      icon={iconAddProject}
      label="Create Project"
    />
  );
};

const groupedLabels = [
  {
    groupName: "",
    subLabels: [{ source: "title" }, { source: "totalProjectPatientCount" }]
  },
  {
    groupName: "Dates",
    subLabels: [{ source: "startedAt" }, { source: "updatedAt" }]
  },
  {
    groupName: "Details",
    subLabels: [
      { source: "type" },
      { source: "createdBy" },
      { source: "description" }
    ]
  }
];

const ProjectList = () => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric"
  };

  mixpanel.track("ProjectList");
  return (
    <Box sx={{ padding: "8px 32px" }}>
      <ListContentHeader>
        <ListContentTitle>All Projects</ListContentTitle>
        <ListActions>
          <CreateNewProjectButton />
        </ListActions>
      </ListContentHeader>
      <List perPage={50} actions={false}>
        <Datagrid
          rowClick="show"
          header={<StudyDatagridHeader groupedLabels={groupedLabels} />}
          bulkActionButtons={false}
          sx={{
            "& .RaDatagrid-headerCell": {
              /* Table column header */
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "16px",
              letterSpacing: "-0.03em",
              textTransform: "capitalize",
              color: runeTheme.palette.primary.main
            }
          }}
        >
          <TextField source="title" label="Project Name" />{" "}
          {/* Open Project with link */}
          <TextField source="totalProjectPatientCount" label="Patients" />
          <DateField
            source="startedAt"
            label="Start date"
            options={dateOptions}
          />
          <DateField
            source="updatedAt"
            label="Last update"
            options={dateOptions}
          />
          <TextField source="type" label="Type" /> {/* Translate to labels */}
          <TextField source="createdBy" label="Creator" />
          <TextField
            source="description"
            label="Description"
            sx={{ maxWidth: "800px" }}
          />
        </Datagrid>
      </List>
    </Box>
  );
};

export default ProjectList;
