import React, { useState } from "react";
import {
  CheckboxGroupInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  Toolbar,
  required,
  Create,
  useRefresh,
  useCreate,
  useNotify
} from "react-admin";

import { styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { runeTheme } from "../../common/RuneTheme";
import { Project } from "../../../types";
import RuneSaveButton from "../../common/buttons/RuneSaveButton";
import SecondaryActionButton from "../../common/buttons/SecondaryActionButton";
import iconAdd from "../../../shared/icon/add.svg";

const Wrapper = styled("div")({
  boxShadow: "none"
});

function CreateCohortToolbar(props: { handleClickClose: () => void }) {
  return (
    <Toolbar
      sx={{
        background: runeTheme.palette.background.light,
        display: "flex",
        justifyContent: "flex-end"
      }}
      disableGutters={true}
    >
      <SecondaryActionButton
        onClick={props.handleClickClose}
        label={"Cancel"}
      />
      <RuneSaveButton label="Create Cohort" icon={iconAdd} />
    </Toolbar>
  );
}

interface ProjectPatient {
  patient: {
    id: number;
  };
  codeName: string;
}

function CreateCohortForm(props: { handleClickClose: () => void }) {
  const { handleClickClose } = props;
  const refresh = useRefresh();
  const [create] = useCreate();
  const notify = useNotify();

  // Fill checkboxes
  const project = useRecordContext<Project>();
  const patientChoices =
    project?.projectPatientList?.projectPatients?.map(
      (projectPatient: ProjectPatient) => {
        return {
          id: projectPatient?.patient?.id,
          codeName: projectPatient?.codeName
        };
      }
    ) || [];

  // Create on cohort creation success
  const addPatientsToCohort = (cohortId: string, cohortPatients: string[]) => {
    if (!cohortPatients) return;

    cohortPatients.forEach((patient: string) => {
      create("CohortPatient", {
        data: {
          cohortId: cohortId,
          patientId: patient
        }
      });
    });
  };

  return (
    <Create
      resource="Cohort"
      component={Wrapper}
      sx={{
        "& .RaCreate-main": {
          boxShadow: "none",
          borderRadius: "8px"
        }
      }}
      redirect={false}
      mutationOptions={{
        meta: { projectId: project.id },
        // https://runelabs.atlassian.net/browse/SW-2470
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        onSuccess(response: any) {
          addPatientsToCohort(response.id, response.addCohortPatients);
          const numPatients = response.addCohortPatients
            ? response.addCohortPatients.length
            : 0;
          delete response.addCohortPatients;
          handleClickClose();
          refresh();
          notify(
            `Cohort Created\nThe new "${response.title}" cohort was created with ${numPatients} patients.`,
            {
              type: "info",
              multiLine: true
            }
          );
        }
      }}
    >
      <SimpleForm
        toolbar={CreateCohortToolbar({ handleClickClose })}
        resource={"Cohort"}
        component={Wrapper}
      >
        <TextInput
          variant="outlined"
          source="title"
          id="title"
          label="Name"
          fullWidth
          validate={required()}
        />
        <TextInput
          variant="outlined"
          source="description"
          multiline={true}
          label="Description"
          fullWidth
        />
        {patientChoices.length > 0 && (
          <CheckboxGroupInput
            variant="outlined"
            id="cohortPatients"
            row={false}
            label={"Please select the patients you want to add to the cohort"}
            source={"cohortPatients"}
            choices={patientChoices}
            optionValue="id"
            optionText="codeName"
            fullWidth
            sx={{
              maxHeight: "20em",
              overflowY: "auto",
              overflowX: "hidden",
              "& label": {
                height: "1em",
                lineHeight: "1em",
                paddingBottom: "0.5em",
                paddingTop: "0.5em",
                overflow: "hidden"
              },
              "& span": {
                overflow: "hidden",
                marginLeft: "1em",
                padding: "0"
              }
            }}
          />
        )}
      </SimpleForm>
    </Create>
  );
}

export default function CreateCohortDialog() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <SecondaryActionButton
        label={"Create Cohort"}
        onClick={handleClickOpen}
        icon={<AddIcon />}
      />
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Create a new cohort</DialogTitle>
        <DialogContent>
          <CreateCohortForm handleClickClose={handleClickClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}
