import { get } from "lodash";
import { useRecordContext } from "react-admin";
import { IconButton, Tooltip, Typography } from "@mui/material";

// Import all icons
import honeycombIcon from "../../shared/icon/honeycomb.png";
import { ReactComponent as MixpanelIcon } from "../../shared/icon/mixpanel.svg";
import { ReactComponent as StrivePDIcon } from "../../shared/icon/strivepd.svg";

interface ExternalLink {
  baseUrl: string;
  iconFilename: string;
  label: string;
  name: string;
  urlGenerator: keyof typeof urlGenerators;
}

interface IconComponents {
  [key: string]: React.ComponentType<React.SVGProps<SVGSVGElement>> | string;
}

// Create an object to map icon filenames to their respective components
const iconComponents: IconComponents = {
  honeycomb: honeycombIcon,
  mixpanel: MixpanelIcon,
  strivepd: StrivePDIcon
};

// URL generator functions
const urlGenerators = {
  getStrivePdPortalUrl: (baseUrl: string, patientId: string): string => {
    console.log("getStrivePdPortalUrl", baseUrl, patientId);
    return baseUrl.replace("{patientId}", patientId);
  },
  getMixpanelUrl: (baseUrl: string, patientId: string): string => {
    console.log("getMixpanelUrl", baseUrl, patientId);
    const filterParts = [
      "resourceType~'user",
      "propertyName~'patientID",
      "propertyObjectKey~null",
      "propertyDefaultType~'string",
      "propertyType~'string",
      "filterOperator~'equals",
      `filterValue~(~'${patientId})`
    ];
    const filterString = `~(~(${filterParts.join("~")}))`;
    return `${baseUrl}&filters=${filterString}`;
  },
  getHoneycombUrl: (baseUrl: string, patientId: string): string => {
    console.log("getHoneycombUrl", baseUrl, patientId);

    const filterParams = {
      time_range: 2419200,
      granularity: 0,
      breakdowns: [
        "service.name",
        "request.endpoint",
        "operation_name",
        "response.status_code"
      ],
      calculations: [{ op: "COUNT" }],
      filters: [
        {
          column: "actor.principal_id",
          op: "=",
          value: patientId
        }
      ],
      filter_combination: "AND",
      orders: [
        { column: "service.name", order: "ascending" },
        { column: "operation_name", order: "ascending" }
      ],
      limit: 1000
    };

    const filterString = encodeURIComponent(JSON.stringify(filterParams));
    return `${baseUrl}?query=${filterString}`;
  }
};

export const PatientQuickLinksField = () => {
  const record = useRecordContext();
  const patientId = get(record, "id") as string;

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const externalLinks =
    ((window as any).Rune?.Carrot?.config?.externalLinks as ExternalLink[]) ||
    [];

  const openExternalLink = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body2" style={{ marginRight: "8px" }}>
        External links:
      </Typography>
      {externalLinks.map((externalLink, index) => {
        console.log(externalLink);

        const IconComponent = iconComponents[externalLink.iconFilename];
        const url = urlGenerators[externalLink.urlGenerator](
          externalLink.baseUrl,
          patientId
        );

        return (
          <Tooltip key={index} title={externalLink.label}>
            <IconButton onClick={() => openExternalLink(url)} size="small">
              {typeof IconComponent === "string" ? (
                <img
                  src={IconComponent}
                  alt={externalLink.name}
                  style={{ width: 20, height: 20 }}
                />
              ) : (
                <IconComponent style={{ width: 20, height: 20 }} />
              )}
            </IconButton>
          </Tooltip>
        );
      })}
    </div>
  );
};
