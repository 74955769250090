import { Show } from "react-admin";
import mixpanel from "mixpanel-browser";

import ProjectHeader from "./ProjectShowHeader";
import ProjectTabs from "./ProjectTabs";

const ProjectShow = () => {
  mixpanel.track("ProjectShow");
  return (
    <Show
      title="Project Details"
      emptyWhileLoading
      actions={false}
      component="div"
      queryOptions={{
        meta: {
          metricCategories: ["TREMOR_DYSKINESIA", "MOBILITY", "SLEEP"]
        }
      }}
    >
      <ProjectHeader />
      <ProjectTabs />
    </Show>
  );
};

export default ProjectShow;
