import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildQueryPatientStreamParams {
  meta: {
    patientId: string;
    symptomType: string;
    nextPageCursor: string;
  };
}

const buildQueryPatientStream = (
  fetchType: string,
  params: BuildQueryPatientStreamParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.GET_LIST:
      return {
        query: gql`
          query getStreamList($filters: StreamQueryFilters) {
            streamList(filters: $filters) {
              streams {
                id
                maxTime
                minTime
                streamType {
                  id
                  name
                  description
                }
                streamTypeId
                parameters {
                  key
                  value
                }
              }
              pageInfo {
                endCursor
              }
            }
          }
        `,
        variables: {
          cursor: params.meta.nextPageCursor,
          filters: {
            patientId: params.meta.patientId,
            streamTypeId: "percentage",
            parameters: [
              {
                key: "category",
                value: "symptom"
              },
              {
                key: "measurement",
                value: params.meta.symptomType
              },
              {
                key: "severity",
                value: "all"
              }
            ]
          }
        },
        parseResponse: (response) => {
          return {
            data: response.data.streamList.streams,
            total: response.data.streamList.streams.length,
            endCursor: response.data.streamList.pageInfo.endCursor
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryPatientStream;
