import gql from "graphql-tag";

interface BuildQueryDataExportParams {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  filter?: any;
  data?: any;
  meta?: any;
  projectId: string;
  id?: string;
  dataExportId?: string;
  limit?: number;
  cursor?: string;
  startMonth?: string;
  endMonth?: string;
}

const buildQueryDataExport = (
  fetchType: string,
  params: BuildQueryDataExportParams
) => {
  switch (fetchType) {
    case "CREATE":
      return {
        query: gql`
          mutation CreateDataExport($input: CreateDataExportInput!) {
            createDataExport(input: $input) {
              dataExport {
                id
                createdBy {
                  email
                }
                startMonth
                endMonth
                status
              }
            }
          }
        `,
        variables: {
          input: {
            projectId: params.meta.projectId,
            startMonth: params.data.startMonth,
            endMonth: params.data.endMonth
          }
        },
        parseResponse: (response: any) => {
          const data = response.data.createDataExport.dataExport;
          return {
            data: data
          };
        }
      };
    case "GET_LIST":
      return {
        query: gql`
          query ($projectId: ID!, $cursor: DateTimeUUIDCursor, $limit: Int) {
            dataExportList(
              projectId: $projectId
              cursor: $cursor
              limit: $limit
            ) {
              dataExports {
                id
                createdBy {
                  email
                }
                startMonth
                endMonth
                createdAt
                completedAt
                status
                dataExportSha256
              }
              pageInfo {
                endCursor
              }
            }
          }
        `,
        variables: {
          projectId: params.filter.projectId,
          limit: params.limit,
          cursor: params.cursor
        },
        parseResponse: (response: any) => {
          const data = response.data.dataExportList.dataExports.map(
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            (dataExport: any) => {
              return {
                ...dataExport,
                createdAt: dataExport.createdAt * 1000,
                completedAt: dataExport.completedAt * 1000
              };
            }
          );
          return {
            data: data,
            total: data.length
          };
        }
      };
    case "GET_ONE":
      return {
        query: gql`
          mutation ($input: RetrieveDataExportInput!) {
            retrieveDataExport(input: $input) {
              downloadUrl
            }
          }
        `,
        variables: {
          input: {
            dataExportId: params.id
          }
        },
        parseResponse: (response: any) => {
          const downloadUrl = response.data.retrieveDataExport.downloadUrl;
          return {
            data: {
              id: params.id,
              presignedUrl: downloadUrl
            }
          };
        }
      };
    default:
      throw new Error(`Unsupported fetch type: ${fetchType}`);
  }
};

export default buildQueryDataExport;
