import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface BuildQueryCohortsParams {
  id: string;
  meta: {
    projectId: string;
  };
  data: {
    id: string;
    title: string;
    description: string;
    cohortPatients: {
      patient: {
        id: string;
        striveUserId: string;
      };
      codeName: string;
    }[];
  };
}

export interface CohortPatient {
  patient: {
    id: string;
    striveUserId: string;
  };
  codeName: string;
}

export interface Cohort {
  id: string;
  cohortPatientList: {
    cohortPatients: CohortPatient[];
  };
}

const buildQueryCohort = (
  fetchType: string,
  params: BuildQueryCohortsParams
): BuildQueryResult => {
  switch (fetchType) {
    case QueryTypes.CREATE:
      return {
        query: gql`
          mutation ($input: CreateCohortInput!) {
            createCohort(input: $input) {
              cohort {
                id
                title
                description
                createdAt
                updatedAt
                createdBy
                updatedBy
                cohortPatientList {
                  aggregatedMetrics {
                    totalPatientCount
                    patientsWithoutSymptomDataCount
                    patientsWithoutHeartRateDataCount
                    patientsWithoutHeartRateAndSymptomDataCount
                  }
                }
              }
            }
          }
        `,
        variables: {
          input: {
            projectId: params.meta.projectId,
            title: params.data.title,
            description: params.data.description
          }
        },
        parseResponse: (response) => {
          const data = response.data.createCohort.cohort;
          data["createdAt"] *= 1000;
          data["updatedAt"] *= 1000;

          if (params.data.cohortPatients)
            data.addCohortPatients = params.data.cohortPatients;

          return {
            data: data
          };
        }
      };
    case QueryTypes.GET_ONE:
      return {
        query: gql`
          query ($cohortId: ID!) {
            org {
              displayName
            }
            cohort(id: $cohortId) {
              id
              title
              description
              createdAt
              updatedAt
              cohortPatientList {
                aggregatedMetrics {
                  totalPatientCount
                  patientsWithoutSymptomDataCount
                  patientsWithoutHeartRateDataCount
                  patientsWithoutHeartRateAndSymptomDataCount
                }
                cohortPatients {
                  patient {
                    id
                    striveUserId
                  }
                  codeName
                }
              }
            }
          }
        `,
        variables: { cohortId: params.id },
        parseResponse: (response) => {
          const data = { ...response.data.cohort };
          data["createdAt"] *= 1000;
          data["updatedAt"] *= 1000;
          return {
            data: data
          };
        }
      };
    case QueryTypes.UPDATE:
      return {
        query: gql`
          mutation ($input: UpdateCohortInput) {
            updateCohort(input: $input) {
              cohort {
                id
                title
                description
                createdAt
                updatedAt
                cohortPatientList {
                  aggregatedMetrics {
                    totalPatientCount
                    patientsWithoutSymptomDataCount
                    patientsWithoutHeartRateDataCount
                    patientsWithoutHeartRateAndSymptomDataCount
                  }
                  cohortPatients {
                    patient {
                      id
                      striveUserId
                    }
                    codeName
                  }
                }
              }
            }
          }
        `,
        variables: {
          input: {
            id: params.data.id,
            title: params.data.title,
            description: params.data.description ? params.data.description : ""
          }
        },
        parseResponse: (response) => {
          const data = { ...response.data.updateCohort.cohort };
          data["createdAt"] *= 1000;
          data["updatedAt"] *= 1000;
          return {
            data: data
          };
        }
      };
    case QueryTypes.DELETE:
      return {
        query: gql`
          mutation ($id: ID!) {
            removeCohort(id: $id) {
              status
            }
          }
        `,
        variables: {
          id: params.id
        },
        parseResponse: (response) => {
          return {
            data: response.data.removeCohort.status,
            total: response.data.removeCohort.status.length
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryCohort;
