import { ComponentType, ErrorInfo, useState } from "react";
import { CoreLayoutProps, Error, ErrorProps } from "react-admin";
import { ErrorBoundary } from "react-error-boundary";
import { styled, SxProps } from "@mui/material";

import { Logger } from "../clients/logger";
import { MixPanelContext } from "../clients/MixPanelContext";
import StudyHeader from "./StudyHeader";
import LogoutOnTabClose from "../providers/auth/LogOutOnTabClose";
import userIdleTimeout from "../providers/auth/LogOutIdleUser";

export interface StudyLayoutProps extends CoreLayoutProps {
  error?: ComponentType<ErrorProps>;
  sx?: SxProps;
}

const StudyLayout = (props: StudyLayoutProps) => {
  const { children, error: errorComponent, title, ...rest } = props;
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>();
  const logger = Logger();

  // Set idle timeout for logged in super admin users.
  userIdleTimeout();

  const handleError = (error: Error, info: ErrorInfo) => {
    logger.error(error);
    setErrorInfo(info);
  };

  const StudyAppContainer = styled("div")({
    display: "flex",
    flexDirection: "column"
  });

  return (
    <MixPanelContext>
      <StudyAppContainer {...rest}>
        <StudyHeader />
        <div id="main-content">
          <ErrorBoundary
            onError={handleError}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <Error
                error={error}
                errorComponent={errorComponent}
                errorInfo={errorInfo}
                resetErrorBoundary={resetErrorBoundary}
                title={title}
              />
            )}
          >
            <LogoutOnTabClose />
            {children}
          </ErrorBoundary>
        </div>
      </StudyAppContainer>
    </MixPanelContext>
  );
};

export default StudyLayout;
