import gql from "graphql-tag";
import { BuildQueryResult } from "ra-data-graphql";
import { QueryTypes } from "../../constants";

interface AppMode {
  id: string;
  displayName: string;
}

const buildQueryAppModes = (fetchType: string): BuildQueryResult => {
  switch (fetchType) {
    // Get all supported App Modes
    case QueryTypes.GET_MANY:
      return {
        query: gql`
          query appModeList {
            appModeList {
              id
              displayName
            }
          }
        `,
        parseResponse: (response) => {
          return {
            data: response.data.appModeList.map((appMode: AppMode) => ({
              ...appMode
            })),
            total: response.data.appModeList.length
          };
        }
      };
  }
  throw Error(`unknown fetch type ${fetchType}`);
};

export default buildQueryAppModes;
